import { IAnalyticsProvider } from './trackingService';
import { ICheckout, ICheckoutTotals, ILineItem } from "../types/types";
import config from "../utils/config";

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[] | undefined;
};

declare const window: WindowWithDataLayer;

class GoogleTagManagerProvider implements IAnalyticsProvider {
  eventMap: Record<string, (data: { checkout: ICheckout, totals: ICheckoutTotals }) => Promise<void>> = {
    'begin_checkout': this.beginCheckout.bind(this),
    'add_shipping_info': this.addShippingInfo.bind(this),
    'add_payment_info': this.addPaymentInfo.bind(this),
    'purchase': this.purchase.bind(this),
  };

  sentBeginCheckout: boolean = false;
  sentPurchase: boolean = false;

  async trackEvent(event: string, data: { checkout: ICheckout, totals: ICheckoutTotals }): Promise<void> {
    if (this.eventMap[event]) {
      await this.eventMap[event](data);
    }
  }

  private async pushToDataLayer(eventData: Record<string, any>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        if (!window.dataLayer) {
          window.dataLayer = [];
        }
        eventData.eventCallback = resolve;
        window.dataLayer.push(eventData);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }

  private mapToEventItem(item: ILineItem): Record<string, any> {
    const productId = item.productId?.replace("gid://shopify/Product/", '') ?? '';
    const variantId = item.variantId?.replace("gid://shopify/ProductVariant/", '') ?? '';
    const variantLabel = item.selectedOptions.map((option) => option.value).join(" / ");

    return {
      item_name: `${item.productTitle} - ${variantLabel}`,
      item_id: `shopify_NZ_${productId}_${variantId}`,
      product_id: productId,
      variant_id: variantId,
      item_brand: "Bushbuck",
      item_category: null,
      quantity: item.quantity,
      currency: 'NZD',
      image_url: item.image.url ?? "",
      url: item.url,
      sku: item.sku,
      price: item.price,
    };
  }

  async beginCheckout(data: { checkout: ICheckout, totals: ICheckoutTotals }): Promise<void> {
    if (this.sentBeginCheckout) {
      return;
    }

    const eventData = {
      event: 'begin_checkout',
      transaction_id: data.checkout.cartId,
      value: data.totals.total,
      tax: data.totals.tax,
      shipping: data.totals.shipping,
      currency: data.totals.currencyCode,
      coupon: data.checkout.discountCode,
      ecommerce: {
        items: data.checkout.lineItems.map(this.mapToEventItem.bind(this)),
        meta_items: data.checkout.lineItems.map((item) => ({
          id: item.productId.replace('gid://shopify/Product/', ''),
          quantity: item.quantity
        }))
      }
    };

    this.sentBeginCheckout = true;
    if (config.environment === 'development') {
      console.log('Sending begin_checkout event to GTM', eventData);
      return;
    }

    await this.pushToDataLayer(eventData);
  }

  async addShippingInfo(data: { checkout: ICheckout, totals: ICheckoutTotals }): Promise<void> {
    const eventData = {
      event: 'add_shipping_info',
      transaction_id: data.checkout.cartId,
      value: data.totals.total,
      tax: data.totals.tax,
      shipping: data.totals.shipping,
      currency: data.totals.currencyCode,
      coupon: data.checkout.discountCode,
      ecommerce: {
        items: data.checkout.lineItems.map(this.mapToEventItem.bind(this)),
        meta_items: data.checkout.lineItems.map((item) => ({
          id: item.productId.replace('gid://shopify/Product/', ''),
          quantity: item.quantity
        }))
      }
    };

    if (config.environment === 'development') {
      console.log('Sending add_shipping_info event to GTM', eventData);
      return;
    }

    await this.pushToDataLayer(eventData);
  }

  async addPaymentInfo(data: { checkout: ICheckout, totals: ICheckoutTotals }): Promise<void> {
    const eventData = {
      event: 'add_payment_info',
      transaction_id: data.checkout.cartId,
      value: data.totals.total,
      tax: data.totals.tax,
      shipping: data.totals.shipping,
      currency: data.totals.currencyCode,
      coupon: data.checkout.discountCode,
      ecommerce: {
        items: data.checkout.lineItems.map(this.mapToEventItem.bind(this)),
        meta_items: data.checkout.lineItems.map((item) => ({
          id: item.productId.replace('gid://shopify/Product/', ''),
          quantity: item.quantity
        }))
      }
    };

    if (config.environment === 'development') {
      console.log('Sending add_payment_info event to GTM', eventData);
      return;
    }

    await this.pushToDataLayer(eventData);
  }

  async purchase(data: { checkout: ICheckout, totals: ICheckoutTotals }): Promise<void> {
    const cartId = data.checkout.cartId.replace('gid://shopify/Cart/', '');
    const hasSentPurchase = localStorage.getItem(`sentPurchase_${cartId}`);
    if (this.sentPurchase || hasSentPurchase) {
      return;
    }

    const eventData = {
      event: 'purchase',
      email: data.checkout.email,
      phone: data.checkout.phone,
      transaction_id: data.checkout.cartId,
      value: data.totals.total,
      tax: data.totals.tax,
      shipping: data.totals.shipping,
      currency: data.totals.currencyCode,
      coupon: data.checkout.discountCode,
      ecommerce: {
        items: data.checkout.lineItems.map(this.mapToEventItem.bind(this)),
        meta_items: data.checkout.lineItems.map((item) => ({
          id: item.productId.replace('gid://shopify/Product/', ''),
          quantity: item.quantity
        }))
      }
    };

    this.sentPurchase = true;
    if (config.environment === 'development') {
      console.log('Sending purchase event to GTM', eventData);
      localStorage.setItem(`sentPurchase_${cartId}`, 'true');
      return;
    }

    await this.pushToDataLayer(eventData);
    localStorage.setItem(`sentPurchase_${cartId}`, 'true');
  }
}

export default GoogleTagManagerProvider;
