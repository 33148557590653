import {Region, regionMap} from "./postcodeMap";

export function postcodeToRegion(postcode: string) : Region {
  if (postcode.length !== 4) {
    throw new Error('Postcode must have exactly four characters.');
  }

  const match = regionMap.find(mapping => mapping.postcode === postcode);

  if (!match) {
    throw new Error('No matching region for postcode prefix: ' + postcode);
  }

  return match;
}
