import React from "react";
import config from "../utils/config";
import { useRouteError } from "react-router-dom";

const ErrorFallback = () => {
  let error = useRouteError();
  console.error(error);

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 mt-10">
      <div className="text-center">
        <p className="text-base font-semibold text-gray-600">Whoops!</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Something went wrong</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we were unable to retrieve the checkout details. The cart may be expired</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href={config.storeUrl}
            className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          >
            Back to store
          </a>
          <a href={`${config.storeUrl}/pages/contact-us`} className="text-sm font-semibold text-gray-900">
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
}
export default ErrorFallback;
