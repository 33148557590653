import formatPrice from "../utils/formatPrice";
import React from "react";
import { ILineItem } from "../types/types";
import { CurrencyCode } from "../types/storefront.generated";

type LineItemProps = {
  lineItem: ILineItem;
  currencyCode: CurrencyCode;
};
const LineItem = ({ lineItem, currencyCode }: LineItemProps) => (
  <li key={lineItem.variantId} className="flex items-start space-x-4 py-6">
    <div className={`relative ${lineItem.quantity < 1 ? "opacity-50" : ""}`}>
      <img
        src={lineItem?.image?.url ?? ""}
        alt={lineItem?.image?.altText ?? ""}
        className="w-20 flex-none rounded-md object-cover object-center"
      />
      {lineItem.quantity > 0 && (
        <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-gray-400 rounded-full -top-2 -right-2">
          {lineItem.quantity}
        </div>
      )}
    </div>

    <div className="w-20 flex-auto space-y-1">
      <>
        <h2 className={`${lineItem.quantity < 1 ? "line-through" : ""}`}>
          {lineItem?.productTitle}
        </h2>
        <h3
          className={`font-light text-gray-500 ${lineItem.quantity < 1 ? "line-through" : ""}`}
        >
          {lineItem?.variantTitle}
        </h3>
      </>
    </div>
    {lineItem.quantity > 0 && lineItem.comparePrice && (
      <>
        <p className="flex-none text-base font-medium line-through">
          {formatPrice(lineItem?.comparePrice, currencyCode)}
        </p>
        <p className="flex-none text-base font-medium text-gray-900">
          {formatPrice(lineItem?.price, currencyCode)}
        </p>
      </>
    )}
    {lineItem.quantity > 0 && !lineItem.comparePrice && (
      <p className="flex-none text-base font-medium">
        {formatPrice(lineItem?.price, currencyCode)}
      </p>
    )}
    {lineItem.quantity < 1 && (
      <p className="flex-none text-base text-red-500 font-medium">
        Out of stock
      </p>
    )}
  </li>
);
export default LineItem;
