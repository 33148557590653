export function currencyToFloat(currencyString: string): number | null {
  const numericString = currencyString.replace(/[^0-9.]/g, '');

  const floatValue = parseFloat(numericString);

  if (!isNaN(floatValue)) {
    return floatValue;
  } else {
    return null;
  }
}
