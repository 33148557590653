import {getSdk as getStorefrontSdk } from "../types/storefront.generated";
import {getSdk as getAdminSdk } from "../types/admin.generated";
import {GraphQLClient} from "graphql-request";
const SHOPIFY_STOREFRONT_URL = process.env.REACT_APP_SHOPIFY_STOREFRONT_URL ?? '';
const SHOPIFY_ADMIN_URL = process.env.SHOPIFY_ADMIN_URL ?? '';
const SHOPIFY_ACCESS_TOKEN = process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN ?? '';
const SHOPIFY_APP_PASSWORD = process.env.SHOPIFY_APP_PASSWORD ?? '';

export const storefrontSdk = () => {
    const storefrontClient = new GraphQLClient(SHOPIFY_STOREFRONT_URL, {
        headers: {
            'X-Shopify-Storefront-Access-Token': SHOPIFY_ACCESS_TOKEN,
            'Content-Type': 'application/json',
        },
    });
    return getStorefrontSdk(storefrontClient);
}

export const adminSdk = () => {
    const adminClient = new GraphQLClient(SHOPIFY_ADMIN_URL, {
        headers: {
            'X-Shopify-Access-Token': SHOPIFY_APP_PASSWORD,
            'Content-Type': 'application/json',
        },
    });
    return getAdminSdk(adminClient);
}
