import formatPrice from "../utils/formatPrice";
import React from "react";
import { ILineItem } from "../types/types";
import { CurrencyCode } from "../types/storefront.generated";

type LineItemProps = {
  lineItem: ILineItem;
  currencyCode: CurrencyCode;
};
const LineItemMobile = ({ lineItem, currencyCode }: LineItemProps) => (
  <li className="flex py-6">
    <div className="h-16 w-12 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
      {lineItem.image && (
        <img
          src={lineItem.image.url ?? ""}
          alt={lineItem.image.altText ?? ""}
          className={`h-full w-full object-cover object-center ${lineItem.quantity < 1 ? "opacity-50" : ""}`}
        />
      )}
    </div>

    <div className="ml-4 flex flex-1 flex-col">
      <div>
        <div className="flex justify-between text-xs font-medium text-gray-900">
          <h3 className={lineItem.quantity < 1 ? "line-through" : ""}>
            {lineItem.productTitle}
          </h3>
          {lineItem.quantity > 0 && lineItem.comparePrice && (
            <div className="flex">
              <p className="ml-4 line-through">
                {formatPrice(lineItem.comparePrice, currencyCode)}
              </p>
              <p className="ml-4">
                {formatPrice(lineItem.price, currencyCode)}
              </p>
            </div>
          )}
          {lineItem.quantity > 0 && !lineItem.comparePrice && (
            <p className="ml-4">{formatPrice(lineItem?.price, currencyCode)}</p>
          )}
          {lineItem.quantity < 1 && (
            <p className="ml-4 text-red-500">Out of stock</p>
          )}
        </div>
        <p
          className={`${lineItem.quantity < 1 ? "line-through" : ""} font-light mt-1 text-sm text-gray-500`}
        >
          {lineItem.variantTitle}
        </p>
      </div>
      {lineItem.quantity > 0 && (
        <div className="flex flex-1 items-end justify-between text-xs">
          <p className="font-light text-gray-500">Qty {lineItem.quantity}</p>
        </div>
      )}
    </div>
  </li>
);
export default LineItemMobile;
