export type Region = {
  postcode: string;
  region: string;
  code: string;
};

export const regionMap: Region[] = [
  { postcode: "0110", code: "NTL", region: "Northland" },
  { postcode: "0112", code: "NTL", region: "Northland" },
  { postcode: "0114", code: "NTL", region: "Northland" },
  { postcode: "0140", code: "NTL", region: "Northland" },
  { postcode: "0141", code: "NTL", region: "Northland" },
  { postcode: "0142", code: "NTL", region: "Northland" },
  { postcode: "0143", code: "NTL", region: "Northland" },
  { postcode: "0144", code: "NTL", region: "Northland" },
  { postcode: "0145", code: "NTL", region: "Northland" },
  { postcode: "0146", code: "NTL", region: "Northland" },
  { postcode: "0147", code: "NTL", region: "Northland" },
  { postcode: "0148", code: "NTL", region: "Northland" },
  { postcode: "0149", code: "NTL", region: "Northland" },
  { postcode: "0150", code: "NTL", region: "Northland" },
  { postcode: "0151", code: "NTL", region: "Northland" },
  { postcode: "0152", code: "NTL", region: "Northland" },
  { postcode: "0153", code: "NTL", region: "Northland" },
  { postcode: "0154", code: "NTL", region: "Northland" },
  { postcode: "0170", code: "NTL", region: "Northland" },
  { postcode: "0171", code: "NTL", region: "Northland" },
  { postcode: "0172", code: "NTL", region: "Northland" },
  { postcode: "0173", code: "NTL", region: "Northland" },
  { postcode: "0174", code: "NTL", region: "Northland" },
  { postcode: "0175", code: "NTL", region: "Northland" },
  { postcode: "0176", code: "NTL", region: "Northland" },
  { postcode: "0178", code: "NTL", region: "Northland" },
  { postcode: "0179", code: "NTL", region: "Northland" },
  { postcode: "0181", code: "NTL", region: "Northland" },
  { postcode: "0182", code: "NTL", region: "Northland" },
  { postcode: "0184", code: "NTL", region: "Northland" },
  { postcode: "0185", code: "NTL", region: "Northland" },
  { postcode: "0192", code: "NTL", region: "Northland" },
  { postcode: "0193", code: "NTL", region: "Northland" },
  { postcode: "0200", code: "NTL", region: "Northland" },
  { postcode: "0241", code: "NTL", region: "Northland" },
  { postcode: "0242", code: "NTL", region: "Northland" },
  { postcode: "0243", code: "NTL", region: "Northland" },
  { postcode: "0244", code: "NTL", region: "Northland" },
  { postcode: "0245", code: "NTL", region: "Northland" },
  { postcode: "0246", code: "NTL", region: "Northland" },
  { postcode: "0247", code: "NTL", region: "Northland" },
  { postcode: "0271", code: "NTL", region: "Northland" },
  { postcode: "0272", code: "NTL", region: "Northland" },
  { postcode: "0281", code: "NTL", region: "Northland" },
  { postcode: "0282", code: "NTL", region: "Northland" },
  { postcode: "0283", code: "NTL", region: "Northland" },
  { postcode: "0293", code: "NTL", region: "Northland" },
  { postcode: "0294", code: "NTL", region: "Northland" },
  { postcode: "0295", code: "NTL", region: "Northland" },
  { postcode: "0310", code: "NTL", region: "Northland" },
  { postcode: "0340", code: "NTL", region: "Northland" },
  { postcode: "0341", code: "NTL", region: "Northland" },
  { postcode: "0370", code: "NTL", region: "Northland" },
  { postcode: "0371", code: "NTL", region: "Northland" },
  { postcode: "0372", code: "NTL", region: "Northland" },
  { postcode: "0373", code: "NTL", region: "Northland" },
  { postcode: "0374", code: "NTL", region: "Northland" },
  { postcode: "0376", code: "NTL", region: "Northland" },
  { postcode: "0377", code: "NTL", region: "Northland" },
  { postcode: "0379", code: "NTL", region: "Northland" },
  { postcode: "0381", code: "NTL", region: "Northland" },
  { postcode: "0391", code: "NTL", region: "Northland" },
  { postcode: "0420", code: "NTL", region: "Northland" },
  { postcode: "0440", code: "NTL", region: "Northland" },
  { postcode: "0441", code: "NTL", region: "Northland" },
  { postcode: "0442", code: "NTL", region: "Northland" },
  { postcode: "0443", code: "NTL", region: "Northland" },
  { postcode: "0444", code: "NTL", region: "Northland" },
  { postcode: "0445", code: "NTL", region: "Northland" },
  { postcode: "0446", code: "NTL", region: "Northland" },
  { postcode: "0447", code: "NTL", region: "Northland" },
  { postcode: "0448", code: "NTL", region: "Northland" },
  { postcode: "0449", code: "NTL", region: "Northland" },
  { postcode: "0451", code: "NTL", region: "Northland" },
  { postcode: "0453", code: "NTL", region: "Northland" },
  { postcode: "0460", code: "NTL", region: "Northland" },
  { postcode: "0462", code: "NTL", region: "Northland" },
  { postcode: "0472", code: "NTL", region: "Northland" },
  { postcode: "0473", code: "NTL", region: "Northland" },
  { postcode: "0474", code: "NTL", region: "Northland" },
  { postcode: "0475", code: "NTL", region: "Northland" },
  { postcode: "0476", code: "NTL", region: "Northland" },
  { postcode: "0478", code: "NTL", region: "Northland" },
  { postcode: "0479", code: "NTL", region: "Northland" },
  { postcode: "0481", code: "NTL", region: "Northland" },
  { postcode: "0482", code: "NTL", region: "Northland" },
  { postcode: "0483", code: "NTL", region: "Northland" },
  { postcode: "0484", code: "NTL", region: "Northland" },
  { postcode: "0486", code: "NTL", region: "Northland" },
  { postcode: "0491", code: "NTL", region: "Northland" },
  { postcode: "0492", code: "NTL", region: "Northland" },
  { postcode: "0494", code: "NTL", region: "Northland" },
  { postcode: "0496", code: "NTL", region: "Northland" },
  { postcode: "0505", code: "NTL", region: "Northland" },
  { postcode: "0520", code: "NTL", region: "Northland" },
  { postcode: "0540", code: "NTL", region: "Northland" },
  { postcode: "0541", code: "NTL", region: "Northland" },
  { postcode: "0542", code: "NTL", region: "Northland" },
  { postcode: "0543", code: "NTL", region: "Northland" },
  { postcode: "0545", code: "NTL", region: "Northland" },
  { postcode: "0547", code: "NTL", region: "Northland" },
  { postcode: "0549", code: "NTL", region: "Northland" },
  { postcode: "0550", code: "NTL", region: "Northland" },
  { postcode: "0571", code: "NTL", region: "Northland" },
  { postcode: "0573", code: "NTL", region: "Northland" },
  { postcode: "0582", code: "NTL", region: "Northland" },
  { postcode: "0583", code: "NTL", region: "Northland" },
  { postcode: "0587", code: "NTL", region: "Northland" },
  { postcode: "0588", code: "NTL", region: "Northland" },
  { postcode: "0589", code: "NTL", region: "Northland" },
  { postcode: "0591", code: "NTL", region: "Northland" },
  { postcode: "0592", code: "NTL", region: "Northland" },
  { postcode: "0593", code: "NTL", region: "Northland" },
  { postcode: "0594", code: "NTL", region: "Northland" },
  { postcode: "0600", code: "AUK", region: "Auckland" },
  { postcode: "0602", code: "AUK", region: "Auckland" },
  { postcode: "0604", code: "AUK", region: "Auckland" },
  { postcode: "0610", code: "AUK", region: "Auckland" },
  { postcode: "0612", code: "AUK", region: "Auckland" },
  { postcode: "0614", code: "AUK", region: "Auckland" },
  { postcode: "0618", code: "AUK", region: "Auckland" },
  { postcode: "0620", code: "AUK", region: "Auckland" },
  { postcode: "0622", code: "AUK", region: "Auckland" },
  { postcode: "0624", code: "AUK", region: "Auckland" },
  { postcode: "0626", code: "AUK", region: "Auckland" },
  { postcode: "0627", code: "AUK", region: "Auckland" },
  { postcode: "0629", code: "AUK", region: "Auckland" },
  { postcode: "0630", code: "AUK", region: "Auckland" },
  { postcode: "0632", code: "AUK", region: "Auckland" },
  { postcode: "0640", code: "AUK", region: "Auckland" },
  { postcode: "0641", code: "AUK", region: "Auckland" },
  { postcode: "0642", code: "AUK", region: "Auckland" },
  { postcode: "0643", code: "AUK", region: "Auckland" },
  { postcode: "0644", code: "AUK", region: "Auckland" },
  { postcode: "0645", code: "AUK", region: "Auckland" },
  { postcode: "0650", code: "AUK", region: "Auckland" },
  { postcode: "0651", code: "AUK", region: "Auckland" },
  { postcode: "0652", code: "AUK", region: "Auckland" },
  { postcode: "0653", code: "AUK", region: "Auckland" },
  { postcode: "0654", code: "AUK", region: "Auckland" },
  { postcode: "0655", code: "AUK", region: "Auckland" },
  { postcode: "0656", code: "AUK", region: "Auckland" },
  { postcode: "0657", code: "AUK", region: "Auckland" },
  { postcode: "0660", code: "AUK", region: "Auckland" },
  { postcode: "0661", code: "AUK", region: "Auckland" },
  { postcode: "0662", code: "AUK", region: "Auckland" },
  { postcode: "0663", code: "AUK", region: "Auckland" },
  { postcode: "0740", code: "AUK", region: "Auckland" },
  { postcode: "0741", code: "AUK", region: "Auckland" },
  { postcode: "0742", code: "AUK", region: "Auckland" },
  { postcode: "0743", code: "AUK", region: "Auckland" },
  { postcode: "0744", code: "AUK", region: "Auckland" },
  { postcode: "0745", code: "AUK", region: "Auckland" },
  { postcode: "0746", code: "AUK", region: "Auckland" },
  { postcode: "0747", code: "AUK", region: "Auckland" },
  { postcode: "0748", code: "AUK", region: "Auckland" },
  { postcode: "0749", code: "AUK", region: "Auckland" },
  { postcode: "0750", code: "AUK", region: "Auckland" },
  { postcode: "0751", code: "AUK", region: "Auckland" },
  { postcode: "0752", code: "AUK", region: "Auckland" },
  { postcode: "0753", code: "AUK", region: "Auckland" },
  { postcode: "0754", code: "AUK", region: "Auckland" },
  { postcode: "0755", code: "AUK", region: "Auckland" },
  { postcode: "0756", code: "AUK", region: "Auckland" },
  { postcode: "0757", code: "AUK", region: "Auckland" },
  { postcode: "0758", code: "AUK", region: "Auckland" },
  { postcode: "0772", code: "AUK", region: "Auckland" },
  { postcode: "0781", code: "AUK", region: "Auckland" },
  { postcode: "0782", code: "AUK", region: "Auckland" },
  { postcode: "0792", code: "AUK", region: "Auckland" },
  { postcode: "0793", code: "AUK", region: "Auckland" },
  { postcode: "0794", code: "AUK", region: "Auckland" },
  { postcode: "0810", code: "AUK", region: "Auckland" },
  { postcode: "0814", code: "AUK", region: "Auckland" },
  { postcode: "0840", code: "AUK", region: "Auckland" },
  { postcode: "0841", code: "AUK", region: "Auckland" },
  { postcode: "0842", code: "AUK", region: "Auckland" },
  { postcode: "0843", code: "AUK", region: "Auckland" },
  { postcode: "0871", code: "AUK", region: "Auckland" },
  { postcode: "0873", code: "AUK", region: "Auckland" },
  { postcode: "0874", code: "AUK", region: "Auckland" },
  { postcode: "0875", code: "AUK", region: "Auckland" },
  { postcode: "0881", code: "AUK", region: "Auckland" },
  { postcode: "0882", code: "AUK", region: "Auckland" },
  { postcode: "0883", code: "AUK", region: "Auckland" },
  { postcode: "0891", code: "AUK", region: "Auckland" },
  { postcode: "0892", code: "AUK", region: "Auckland" },
  { postcode: "0920", code: "AUK", region: "Auckland" },
  { postcode: "0930", code: "AUK", region: "Auckland" },
  { postcode: "0931", code: "AUK", region: "Auckland" },
  { postcode: "0932", code: "AUK", region: "Auckland" },
  { postcode: "0940", code: "AUK", region: "Auckland" },
  { postcode: "0941", code: "AUK", region: "Auckland" },
  { postcode: "0942", code: "AUK", region: "Auckland" },
  { postcode: "0943", code: "AUK", region: "Auckland" },
  { postcode: "0944", code: "AUK", region: "Auckland" },
  { postcode: "0945", code: "AUK", region: "Auckland" },
  { postcode: "0946", code: "AUK", region: "Auckland" },
  { postcode: "0947", code: "AUK", region: "Auckland" },
  { postcode: "0948", code: "AUK", region: "Auckland" },
  { postcode: "0950", code: "AUK", region: "Auckland" },
  { postcode: "0951", code: "AUK", region: "Auckland" },
  { postcode: "0960", code: "AUK", region: "Auckland" },
  { postcode: "0961", code: "AUK", region: "Auckland" },
  { postcode: "0962", code: "AUK", region: "Auckland" },
  { postcode: "0963", code: "AUK", region: "Auckland" },
  { postcode: "0972", code: "AUK", region: "Auckland" },
  { postcode: "0973", code: "AUK", region: "Auckland" },
  { postcode: "0974", code: "AUK", region: "Auckland" },
  { postcode: "0975", code: "NTL", region: "Northland" },
  { postcode: "0977", code: "AUK", region: "Auckland" },
  { postcode: "0981", code: "AUK", region: "Auckland" },
  { postcode: "0982", code: "AUK", region: "Auckland" },
  { postcode: "0983", code: "AUK", region: "Auckland" },
  { postcode: "0984", code: "AUK", region: "Auckland" },
  { postcode: "0985", code: "AUK", region: "Auckland" },
  { postcode: "0986", code: "AUK", region: "Auckland" },
  { postcode: "0991", code: "AUK", region: "Auckland" },
  { postcode: "0992", code: "AUK", region: "Auckland" },
  { postcode: "0993", code: "AUK", region: "Auckland" },
  { postcode: "1010", code: "AUK", region: "Auckland" },
  { postcode: "1011", code: "AUK", region: "Auckland" },
  { postcode: "1021", code: "AUK", region: "Auckland" },
  { postcode: "1022", code: "AUK", region: "Auckland" },
  { postcode: "1023", code: "AUK", region: "Auckland" },
  { postcode: "1024", code: "AUK", region: "Auckland" },
  { postcode: "1025", code: "AUK", region: "Auckland" },
  { postcode: "1026", code: "AUK", region: "Auckland" },
  { postcode: "1041", code: "AUK", region: "Auckland" },
  { postcode: "1042", code: "AUK", region: "Auckland" },
  { postcode: "1050", code: "AUK", region: "Auckland" },
  { postcode: "1051", code: "AUK", region: "Auckland" },
  { postcode: "1052", code: "AUK", region: "Auckland" },
  { postcode: "1060", code: "AUK", region: "Auckland" },
  { postcode: "1061", code: "AUK", region: "Auckland" },
  { postcode: "1062", code: "AUK", region: "Auckland" },
  { postcode: "1071", code: "AUK", region: "Auckland" },
  { postcode: "1072", code: "AUK", region: "Auckland" },
  { postcode: "1081", code: "AUK", region: "Auckland" },
  { postcode: "1140", code: "AUK", region: "Auckland" },
  { postcode: "1141", code: "AUK", region: "Auckland" },
  { postcode: "1142", code: "AUK", region: "Auckland" },
  { postcode: "1143", code: "AUK", region: "Auckland" },
  { postcode: "1144", code: "AUK", region: "Auckland" },
  { postcode: "1145", code: "AUK", region: "Auckland" },
  { postcode: "1147", code: "AUK", region: "Auckland" },
  { postcode: "1148", code: "AUK", region: "Auckland" },
  { postcode: "1149", code: "AUK", region: "Auckland" },
  { postcode: "1150", code: "AUK", region: "Auckland" },
  { postcode: "1151", code: "AUK", region: "Auckland" },
  { postcode: "1245", code: "AUK", region: "Auckland" },
  { postcode: "1246", code: "AUK", region: "Auckland" },
  { postcode: "1340", code: "AUK", region: "Auckland" },
  { postcode: "1342", code: "AUK", region: "Auckland" },
  { postcode: "1344", code: "AUK", region: "Auckland" },
  { postcode: "1345", code: "AUK", region: "Auckland" },
  { postcode: "1346", code: "AUK", region: "Auckland" },
  { postcode: "1347", code: "AUK", region: "Auckland" },
  { postcode: "1348", code: "AUK", region: "Auckland" },
  { postcode: "1349", code: "AUK", region: "Auckland" },
  { postcode: "1350", code: "AUK", region: "Auckland" },
  { postcode: "1351", code: "AUK", region: "Auckland" },
  { postcode: "1352", code: "AUK", region: "Auckland" },
  { postcode: "1440", code: "AUK", region: "Auckland" },
  { postcode: "1443", code: "AUK", region: "Auckland" },
  { postcode: "1445", code: "AUK", region: "Auckland" },
  { postcode: "1446", code: "AUK", region: "Auckland" },
  { postcode: "1541", code: "AUK", region: "Auckland" },
  { postcode: "1542", code: "AUK", region: "Auckland" },
  { postcode: "1543", code: "AUK", region: "Auckland" },
  { postcode: "1544", code: "AUK", region: "Auckland" },
  { postcode: "1545", code: "AUK", region: "Auckland" },
  { postcode: "1546", code: "AUK", region: "Auckland" },
  { postcode: "1640", code: "AUK", region: "Auckland" },
  { postcode: "1641", code: "AUK", region: "Auckland" },
  { postcode: "1642", code: "AUK", region: "Auckland" },
  { postcode: "1643", code: "AUK", region: "Auckland" },
  { postcode: "1740", code: "AUK", region: "Auckland" },
  { postcode: "1741", code: "AUK", region: "Auckland" },
  { postcode: "1742", code: "AUK", region: "Auckland" },
  { postcode: "1743", code: "AUK", region: "Auckland" },
  { postcode: "1744", code: "AUK", region: "Auckland" },
  { postcode: "1745", code: "AUK", region: "Auckland" },
  { postcode: "1746", code: "AUK", region: "Auckland" },
  { postcode: "1840", code: "AUK", region: "Auckland" },
  { postcode: "1841", code: "AUK", region: "Auckland" },
  { postcode: "1842", code: "AUK", region: "Auckland" },
  { postcode: "1843", code: "AUK", region: "Auckland" },
  { postcode: "1971", code: "AUK", region: "Auckland" },
  { postcode: "2010", code: "AUK", region: "Auckland" },
  { postcode: "2012", code: "AUK", region: "Auckland" },
  { postcode: "2013", code: "AUK", region: "Auckland" },
  { postcode: "2014", code: "AUK", region: "Auckland" },
  { postcode: "2016", code: "AUK", region: "Auckland" },
  { postcode: "2018", code: "AUK", region: "Auckland" },
  { postcode: "2022", code: "AUK", region: "Auckland" },
  { postcode: "2023", code: "AUK", region: "Auckland" },
  { postcode: "2024", code: "AUK", region: "Auckland" },
  { postcode: "2025", code: "AUK", region: "Auckland" },
  { postcode: "2102", code: "AUK", region: "Auckland" },
  { postcode: "2103", code: "AUK", region: "Auckland" },
  { postcode: "2104", code: "AUK", region: "Auckland" },
  { postcode: "2105", code: "AUK", region: "Auckland" },
  { postcode: "2110", code: "AUK", region: "Auckland" },
  { postcode: "2112", code: "AUK", region: "Auckland" },
  { postcode: "2113", code: "AUK", region: "Auckland" },
  { postcode: "2120", code: "AUK", region: "Auckland" },
  { postcode: "2121", code: "WKO", region: "Waikato" },
  { postcode: "2122", code: "AUK", region: "Auckland" },
  { postcode: "2123", code: "AUK", region: "Auckland" },
  { postcode: "2140", code: "AUK", region: "Auckland" },
  { postcode: "2141", code: "AUK", region: "Auckland" },
  { postcode: "2142", code: "AUK", region: "Auckland" },
  { postcode: "2143", code: "AUK", region: "Auckland" },
  { postcode: "2144", code: "AUK", region: "Auckland" },
  { postcode: "2145", code: "AUK", region: "Auckland" },
  { postcode: "2146", code: "AUK", region: "Auckland" },
  { postcode: "2147", code: "AUK", region: "Auckland" },
  { postcode: "2148", code: "AUK", region: "Auckland" },
  { postcode: "2149", code: "AUK", region: "Auckland" },
  { postcode: "2150", code: "AUK", region: "Auckland" },
  { postcode: "2151", code: "AUK", region: "Auckland" },
  { postcode: "2153", code: "AUK", region: "Auckland" },
  { postcode: "2154", code: "AUK", region: "Auckland" },
  { postcode: "2155", code: "AUK", region: "Auckland" },
  { postcode: "2156", code: "AUK", region: "Auckland" },
  { postcode: "2157", code: "AUK", region: "Auckland" },
  { postcode: "2158", code: "AUK", region: "Auckland" },
  { postcode: "2159", code: "AUK", region: "Auckland" },
  { postcode: "2160", code: "AUK", region: "Auckland" },
  { postcode: "2240", code: "AUK", region: "Auckland" },
  { postcode: "2241", code: "AUK", region: "Auckland" },
  { postcode: "2242", code: "AUK", region: "Auckland" },
  { postcode: "2243", code: "AUK", region: "Auckland" },
  { postcode: "2244", code: "AUK", region: "Auckland" },
  { postcode: "2245", code: "AUK", region: "Auckland" },
  { postcode: "2246", code: "AUK", region: "Auckland" },
  { postcode: "2247", code: "AUK", region: "Auckland" },
  { postcode: "2248", code: "AUK", region: "Auckland" },
  { postcode: "2340", code: "AUK", region: "Auckland" },
  { postcode: "2341", code: "AUK", region: "Auckland" },
  { postcode: "2342", code: "WKO", region: "Waikato" },
  { postcode: "2343", code: "AUK", region: "Auckland" },
  { postcode: "2344", code: "AUK", region: "Auckland" },
  { postcode: "2345", code: "AUK", region: "Auckland" },
  { postcode: "2440", code: "WKO", region: "Waikato" },
  { postcode: "2441", code: "WKO", region: "Waikato" },
  { postcode: "2471", code: "AUK", region: "Auckland" },
  { postcode: "2472", code: "WKO", region: "Waikato" },
  { postcode: "2473", code: "WKO", region: "Waikato" },
  { postcode: "2474", code: "WKO", region: "Waikato" },
  { postcode: "2571", code: "AUK", region: "Auckland" },
  { postcode: "2576", code: "AUK", region: "Auckland" },
  { postcode: "2577", code: "AUK", region: "Auckland" },
  { postcode: "2578", code: "AUK", region: "Auckland" },
  { postcode: "2579", code: "AUK", region: "Auckland" },
  { postcode: "2580", code: "AUK", region: "Auckland" },
  { postcode: "2582", code: "AUK", region: "Auckland" },
  { postcode: "2583", code: "AUK", region: "Auckland" },
  { postcode: "2584", code: "AUK", region: "Auckland" },
  { postcode: "2585", code: "AUK", region: "Auckland" },
  { postcode: "2675", code: "AUK", region: "Auckland" },
  { postcode: "2676", code: "AUK", region: "Auckland" },
  { postcode: "2677", code: "WKO", region: "Waikato" },
  { postcode: "2678", code: "AUK", region: "Auckland" },
  { postcode: "2679", code: "AUK", region: "Auckland" },
  { postcode: "2681", code: "WKO", region: "Waikato" },
  { postcode: "2682", code: "AUK", region: "Auckland" },
  { postcode: "2683", code: "AUK", region: "Auckland" },
  { postcode: "2684", code: "AUK", region: "Auckland" },
  { postcode: "2693", code: "WKO", region: "Waikato" },
  { postcode: "2694", code: "WKO", region: "Waikato" },
  { postcode: "2695", code: "WKO", region: "Waikato" },
  { postcode: "2696", code: "WKO", region: "Waikato" },
  { postcode: "2697", code: "WKO", region: "Waikato" },
  { postcode: "3010", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3015", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3020", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3025", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3040", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3041", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3042", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3043", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3044", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3045", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3046", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3047", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3048", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3049", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3060", code: "WKO", region: "Waikato" },
  { postcode: "3062", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3072", code: "WKO", region: "Waikato" },
  { postcode: "3073", code: "WKO", region: "Waikato" },
  { postcode: "3074", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3076", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3077", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3078", code: "WKO", region: "Waikato" },
  { postcode: "3079", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3081", code: "WKO", region: "Waikato" },
  { postcode: "3083", code: "WKO", region: "Waikato" },
  { postcode: "3110", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3112", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3114", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3116", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3118", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3119", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3120", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3121", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3122", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3123", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3127", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3129", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3140", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3141", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3142", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3143", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3144", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3145", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3146", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3147", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3148", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3149", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3150", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3151", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3152", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3153", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3154", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3158", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3159", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3160", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3161", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3162", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3163", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3164", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3166", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3167", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3168", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3169", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3171", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3172", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3173", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3175", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3176", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3177", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3178", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3179", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3180", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3181", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3182", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3183", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3186", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3187", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3188", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3189", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3191", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3192", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3193", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3194", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3196", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3197", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3198", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3199", code: "GIS", region: "Gisborne" },
  { postcode: "3200", code: "WKO", region: "Waikato" },
  { postcode: "3204", code: "WKO", region: "Waikato" },
  { postcode: "3206", code: "WKO", region: "Waikato" },
  { postcode: "3210", code: "WKO", region: "Waikato" },
  { postcode: "3214", code: "WKO", region: "Waikato" },
  { postcode: "3216", code: "WKO", region: "Waikato" },
  { postcode: "3218", code: "WKO", region: "Waikato" },
  { postcode: "3225", code: "WKO", region: "Waikato" },
  { postcode: "3240", code: "WKO", region: "Waikato" },
  { postcode: "3241", code: "WKO", region: "Waikato" },
  { postcode: "3242", code: "WKO", region: "Waikato" },
  { postcode: "3243", code: "WKO", region: "Waikato" },
  { postcode: "3244", code: "WKO", region: "Waikato" },
  { postcode: "3245", code: "WKO", region: "Waikato" },
  { postcode: "3246", code: "WKO", region: "Waikato" },
  { postcode: "3247", code: "WKO", region: "Waikato" },
  { postcode: "3248", code: "WKO", region: "Waikato" },
  { postcode: "3249", code: "WKO", region: "Waikato" },
  { postcode: "3251", code: "WKO", region: "Waikato" },
  { postcode: "3252", code: "WKO", region: "Waikato" },
  { postcode: "3253", code: "WKO", region: "Waikato" },
  { postcode: "3254", code: "WKO", region: "Waikato" },
  { postcode: "3255", code: "WKO", region: "Waikato" },
  { postcode: "3256", code: "WKO", region: "Waikato" },
  { postcode: "3257", code: "WKO", region: "Waikato" },
  { postcode: "3260", code: "WKO", region: "Waikato" },
  { postcode: "3261", code: "WKO", region: "Waikato" },
  { postcode: "3262", code: "WKO", region: "Waikato" },
  { postcode: "3263", code: "WKO", region: "Waikato" },
  { postcode: "3264", code: "WKO", region: "Waikato" },
  { postcode: "3265", code: "WKO", region: "Waikato" },
  { postcode: "3266", code: "WKO", region: "Waikato" },
  { postcode: "3281", code: "WKO", region: "Waikato" },
  { postcode: "3282", code: "WKO", region: "Waikato" },
  { postcode: "3283", code: "WKO", region: "Waikato" },
  { postcode: "3284", code: "WKO", region: "Waikato" },
  { postcode: "3285", code: "WKO", region: "Waikato" },
  { postcode: "3288", code: "WKO", region: "Waikato" },
  { postcode: "3289", code: "WKO", region: "Waikato" },
  { postcode: "3290", code: "WKO", region: "Waikato" },
  { postcode: "3293", code: "WKO", region: "Waikato" },
  { postcode: "3295", code: "WKO", region: "Waikato" },
  { postcode: "3296", code: "WKO", region: "Waikato" },
  { postcode: "3297", code: "WKO", region: "Waikato" },
  { postcode: "3300", code: "WKO", region: "Waikato" },
  { postcode: "3310", code: "WKO", region: "Waikato" },
  { postcode: "3320", code: "WKO", region: "Waikato" },
  { postcode: "3330", code: "WKO", region: "Waikato" },
  { postcode: "3332", code: "WKO", region: "Waikato" },
  { postcode: "3334", code: "WKO", region: "Waikato" },
  { postcode: "3340", code: "WKO", region: "Waikato" },
  { postcode: "3341", code: "WKO", region: "Waikato" },
  { postcode: "3342", code: "WKO", region: "Waikato" },
  { postcode: "3343", code: "WKO", region: "Waikato" },
  { postcode: "3351", code: "WKO", region: "Waikato" },
  { postcode: "3352", code: "WKO", region: "Waikato" },
  { postcode: "3353", code: "WKO", region: "Waikato" },
  { postcode: "3360", code: "WKO", region: "Waikato" },
  { postcode: "3371", code: "WKO", region: "Waikato" },
  { postcode: "3372", code: "WKO", region: "Waikato" },
  { postcode: "3373", code: "WKO", region: "Waikato" },
  { postcode: "3374", code: "WKO", region: "Waikato" },
  { postcode: "3375", code: "WKO", region: "Waikato" },
  { postcode: "3377", code: "WKO", region: "Waikato" },
  { postcode: "3378", code: "WKO", region: "Waikato" },
  { postcode: "3379", code: "HKB", region: "Hawke's Bay" },
  { postcode: "3380", code: "WKO", region: "Waikato" },
  { postcode: "3381", code: "WKO", region: "Waikato" },
  { postcode: "3382", code: "WKO", region: "Waikato" },
  { postcode: "3391", code: "WKO", region: "Waikato" },
  { postcode: "3392", code: "WKO", region: "Waikato" },
  { postcode: "3393", code: "WKO", region: "Waikato" },
  { postcode: "3400", code: "WKO", region: "Waikato" },
  { postcode: "3401", code: "WKO", region: "Waikato" },
  { postcode: "3410", code: "WKO", region: "Waikato" },
  { postcode: "3411", code: "WKO", region: "Waikato" },
  { postcode: "3415", code: "WKO", region: "Waikato" },
  { postcode: "3420", code: "WKO", region: "Waikato" },
  { postcode: "3421", code: "WKO", region: "Waikato" },
  { postcode: "3432", code: "WKO", region: "Waikato" },
  { postcode: "3434", code: "WKO", region: "Waikato" },
  { postcode: "3440", code: "WKO", region: "Waikato" },
  { postcode: "3441", code: "WKO", region: "Waikato" },
  { postcode: "3442", code: "WKO", region: "Waikato" },
  { postcode: "3443", code: "WKO", region: "Waikato" },
  { postcode: "3444", code: "WKO", region: "Waikato" },
  { postcode: "3445", code: "WKO", region: "Waikato" },
  { postcode: "3450", code: "WKO", region: "Waikato" },
  { postcode: "3451", code: "WKO", region: "Waikato" },
  { postcode: "3471", code: "WKO", region: "Waikato" },
  { postcode: "3472", code: "WKO", region: "Waikato" },
  { postcode: "3473", code: "WKO", region: "Waikato" },
  { postcode: "3474", code: "WKO", region: "Waikato" },
  { postcode: "3475", code: "WKO", region: "Waikato" },
  { postcode: "3481", code: "WKO", region: "Waikato" },
  { postcode: "3482", code: "WKO", region: "Waikato" },
  { postcode: "3483", code: "WKO", region: "Waikato" },
  { postcode: "3484", code: "WKO", region: "Waikato" },
  { postcode: "3485", code: "WKO", region: "Waikato" },
  { postcode: "3491", code: "WKO", region: "Waikato" },
  { postcode: "3492", code: "WKO", region: "Waikato" },
  { postcode: "3493", code: "WKO", region: "Waikato" },
  { postcode: "3494", code: "WKO", region: "Waikato" },
  { postcode: "3495", code: "WKO", region: "Waikato" },
  { postcode: "3496", code: "WKO", region: "Waikato" },
  { postcode: "3500", code: "WKO", region: "Waikato" },
  { postcode: "3503", code: "WKO", region: "Waikato" },
  { postcode: "3506", code: "WKO", region: "Waikato" },
  { postcode: "3508", code: "WKO", region: "Waikato" },
  { postcode: "3510", code: "WKO", region: "Waikato" },
  { postcode: "3540", code: "WKO", region: "Waikato" },
  { postcode: "3541", code: "WKO", region: "Waikato" },
  { postcode: "3542", code: "WKO", region: "Waikato" },
  { postcode: "3543", code: "WKO", region: "Waikato" },
  { postcode: "3544", code: "WKO", region: "Waikato" },
  { postcode: "3545", code: "WKO", region: "Waikato" },
  { postcode: "3546", code: "WKO", region: "Waikato" },
  { postcode: "3547", code: "WKO", region: "Waikato" },
  { postcode: "3574", code: "WKO", region: "Waikato" },
  { postcode: "3575", code: "WKO", region: "Waikato" },
  { postcode: "3576", code: "WKO", region: "Waikato" },
  { postcode: "3577", code: "WKO", region: "Waikato" },
  { postcode: "3578", code: "WKO", region: "Waikato" },
  { postcode: "3579", code: "WKO", region: "Waikato" },
  { postcode: "3581", code: "WKO", region: "Waikato" },
  { postcode: "3582", code: "WKO", region: "Waikato" },
  { postcode: "3583", code: "WKO", region: "Waikato" },
  { postcode: "3584", code: "WKO", region: "Waikato" },
  { postcode: "3591", code: "WKO", region: "Waikato" },
  { postcode: "3592", code: "WKO", region: "Waikato" },
  { postcode: "3597", code: "WKO", region: "Waikato" },
  { postcode: "3600", code: "WKO", region: "Waikato" },
  { postcode: "3610", code: "WKO", region: "Waikato" },
  { postcode: "3611", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3620", code: "WKO", region: "Waikato" },
  { postcode: "3640", code: "WKO", region: "Waikato" },
  { postcode: "3641", code: "WKO", region: "Waikato" },
  { postcode: "3642", code: "BOP", region: "Bay of Plenty" },
  { postcode: "3643", code: "WKO", region: "Waikato" },
  { postcode: "3671", code: "WKO", region: "Waikato" },
  { postcode: "3672", code: "WKO", region: "Waikato" },
  { postcode: "3673", code: "WKO", region: "Waikato" },
  { postcode: "3674", code: "WKO", region: "Waikato" },
  { postcode: "3681", code: "WKO", region: "Waikato" },
  { postcode: "3682", code: "WKO", region: "Waikato" },
  { postcode: "3691", code: "WKO", region: "Waikato" },
  { postcode: "3700", code: "WKO", region: "Waikato" },
  { postcode: "3710", code: "WKO", region: "Waikato" },
  { postcode: "3720", code: "WKO", region: "Waikato" },
  { postcode: "3721", code: "WKO", region: "Waikato" },
  { postcode: "3740", code: "WKO", region: "Waikato" },
  { postcode: "3741", code: "WKO", region: "Waikato" },
  { postcode: "3742", code: "WKO", region: "Waikato" },
  { postcode: "3771", code: "WKO", region: "Waikato" },
  { postcode: "3772", code: "WKO", region: "Waikato" },
  { postcode: "3781", code: "WKO", region: "Waikato" },
  { postcode: "3782", code: "WKO", region: "Waikato" },
  { postcode: "3784", code: "WKO", region: "Waikato" },
  { postcode: "3791", code: "WKO", region: "Waikato" },
  { postcode: "3792", code: "WKO", region: "Waikato" },
  { postcode: "3793", code: "WKO", region: "Waikato" },
  { postcode: "3794", code: "WKO", region: "Waikato" },
  { postcode: "3800", code: "WKO", region: "Waikato" },
  { postcode: "3802", code: "WKO", region: "Waikato" },
  { postcode: "3803", code: "WKO", region: "Waikato" },
  { postcode: "3840", code: "WKO", region: "Waikato" },
  { postcode: "3841", code: "WKO", region: "Waikato" },
  { postcode: "3843", code: "WKO", region: "Waikato" },
  { postcode: "3872", code: "WKO", region: "Waikato" },
  { postcode: "3873", code: "WKO", region: "Waikato" },
  { postcode: "3874", code: "WKO", region: "Waikato" },
  { postcode: "3875", code: "WKO", region: "Waikato" },
  { postcode: "3876", code: "WKO", region: "Waikato" },
  { postcode: "3877", code: "WKO", region: "Waikato" },
  { postcode: "3878", code: "WKO", region: "Waikato" },
  { postcode: "3879", code: "WKO", region: "Waikato" },
  { postcode: "3880", code: "WKO", region: "Waikato" },
  { postcode: "3881", code: "WKO", region: "Waikato" },
  { postcode: "3882", code: "WKO", region: "Waikato" },
  { postcode: "3883", code: "WKO", region: "Waikato" },
  { postcode: "3885", code: "WKO", region: "Waikato" },
  { postcode: "3886", code: "WKO", region: "Waikato" },
  { postcode: "3889", code: "WKO", region: "Waikato" },
  { postcode: "3894", code: "WKO", region: "Waikato" },
  { postcode: "3895", code: "WKO", region: "Waikato" },
  { postcode: "3900", code: "WKO", region: "Waikato" },
  { postcode: "3910", code: "WKO", region: "Waikato" },
  { postcode: "3912", code: "WKO", region: "Waikato" },
  { postcode: "3920", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3924", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3926", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3940", code: "WKO", region: "Waikato" },
  { postcode: "3941", code: "WKO", region: "Waikato" },
  { postcode: "3942", code: "WKO", region: "Waikato" },
  { postcode: "3943", code: "WKO", region: "Waikato" },
  { postcode: "3944", code: "WKO", region: "Waikato" },
  { postcode: "3945", code: "WKO", region: "Waikato" },
  { postcode: "3946", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3947", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3948", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3949", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3970", code: "WKO", region: "Waikato" },
  { postcode: "3971", code: "WKO", region: "Waikato" },
  { postcode: "3972", code: "WKO", region: "Waikato" },
  { postcode: "3973", code: "WKO", region: "Waikato" },
  { postcode: "3974", code: "WKO", region: "Waikato" },
  { postcode: "3975", code: "WKO", region: "Waikato" },
  { postcode: "3976", code: "WKO", region: "Waikato" },
  { postcode: "3977", code: "WKO", region: "Waikato" },
  { postcode: "3978", code: "WKO", region: "Waikato" },
  { postcode: "3979", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3980", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3981", code: "WKO", region: "Waikato" },
  { postcode: "3982", code: "WKO", region: "Waikato" },
  { postcode: "3983", code: "WKO", region: "Waikato" },
  { postcode: "3985", code: "WKO", region: "Waikato" },
  { postcode: "3986", code: "WKO", region: "Waikato" },
  { postcode: "3987", code: "WKO", region: "Waikato" },
  { postcode: "3988", code: "WKO", region: "Waikato" },
  { postcode: "3989", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3990", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3991", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3992", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3993", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3994", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3995", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3996", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3997", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "3998", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4010", code: "GIS", region: "Gisborne" },
  { postcode: "4022", code: "GIS", region: "Gisborne" },
  { postcode: "4032", code: "GIS", region: "Gisborne" },
  { postcode: "4040", code: "GIS", region: "Gisborne" },
  { postcode: "4041", code: "GIS", region: "Gisborne" },
  { postcode: "4042", code: "GIS", region: "Gisborne" },
  { postcode: "4043", code: "GIS", region: "Gisborne" },
  { postcode: "4044", code: "GIS", region: "Gisborne" },
  { postcode: "4045", code: "GIS", region: "Gisborne" },
  { postcode: "4046", code: "GIS", region: "Gisborne" },
  { postcode: "4047", code: "GIS", region: "Gisborne" },
  { postcode: "4048", code: "GIS", region: "Gisborne" },
  { postcode: "4049", code: "GIS", region: "Gisborne" },
  { postcode: "4050", code: "GIS", region: "Gisborne" },
  { postcode: "4051", code: "GIS", region: "Gisborne" },
  { postcode: "4052", code: "GIS", region: "Gisborne" },
  { postcode: "4071", code: "GIS", region: "Gisborne" },
  { postcode: "4072", code: "GIS", region: "Gisborne" },
  { postcode: "4073", code: "GIS", region: "Gisborne" },
  { postcode: "4075", code: "GIS", region: "Gisborne" },
  { postcode: "4077", code: "GIS", region: "Gisborne" },
  { postcode: "4078", code: "GIS", region: "Gisborne" },
  { postcode: "4079", code: "GIS", region: "Gisborne" },
  { postcode: "4081", code: "GIS", region: "Gisborne" },
  { postcode: "4082", code: "GIS", region: "Gisborne" },
  { postcode: "4083", code: "GIS", region: "Gisborne" },
  { postcode: "4086", code: "GIS", region: "Gisborne" },
  { postcode: "4087", code: "GIS", region: "Gisborne" },
  { postcode: "4091", code: "GIS", region: "Gisborne" },
  { postcode: "4092", code: "GIS", region: "Gisborne" },
  { postcode: "4093", code: "GIS", region: "Gisborne" },
  { postcode: "4094", code: "GIS", region: "Gisborne" },
  { postcode: "4102", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4104", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4108", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4110", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4112", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4120", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4122", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4130", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4140", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4141", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4142", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4143", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4144", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4145", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4146", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4147", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4148", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4149", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4153", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4154", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4155", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4156", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4157", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4158", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4159", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4160", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4161", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4162", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4163", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4164", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4165", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4166", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4171", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4172", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4174", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4175", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4178", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4179", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4181", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4182", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4183", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4184", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4186", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4188", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4189", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4191", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4193", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4195", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4196", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4197", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4198", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4200", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4202", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4203", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4210", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4240", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4241", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4242", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4243", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4244", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4245", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4271", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4272", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4273", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4274", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4275", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4276", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4277", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4278", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4279", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4281", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4282", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4283", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4284", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4285", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4286", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4287", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4288", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4291", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4292", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4293", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4294", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4295", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4310", code: "TKI", region: "Taranaki" },
  { postcode: "4312", code: "TKI", region: "Taranaki" },
  { postcode: "4314", code: "TKI", region: "Taranaki" },
  { postcode: "4320", code: "TKI", region: "Taranaki" },
  { postcode: "4322", code: "TKI", region: "Taranaki" },
  { postcode: "4330", code: "TKI", region: "Taranaki" },
  { postcode: "4332", code: "TKI", region: "Taranaki" },
  { postcode: "4335", code: "TKI", region: "Taranaki" },
  { postcode: "4340", code: "TKI", region: "Taranaki" },
  { postcode: "4341", code: "TKI", region: "Taranaki" },
  { postcode: "4342", code: "TKI", region: "Taranaki" },
  { postcode: "4343", code: "TKI", region: "Taranaki" },
  { postcode: "4344", code: "TKI", region: "Taranaki" },
  { postcode: "4345", code: "TKI", region: "Taranaki" },
  { postcode: "4346", code: "TKI", region: "Taranaki" },
  { postcode: "4347", code: "TKI", region: "Taranaki" },
  { postcode: "4348", code: "TKI", region: "Taranaki" },
  { postcode: "4349", code: "TKI", region: "Taranaki" },
  { postcode: "4350", code: "WKO", region: "Waikato" },
  { postcode: "4351", code: "TKI", region: "Taranaki" },
  { postcode: "4352", code: "TKI", region: "Taranaki" },
  { postcode: "4353", code: "TKI", region: "Taranaki" },
  { postcode: "4360", code: "TKI", region: "Taranaki" },
  { postcode: "4371", code: "TKI", region: "Taranaki" },
  { postcode: "4372", code: "TKI", region: "Taranaki" },
  { postcode: "4373", code: "TKI", region: "Taranaki" },
  { postcode: "4374", code: "TKI", region: "Taranaki" },
  { postcode: "4375", code: "TKI", region: "Taranaki" },
  { postcode: "4376", code: "WKO", region: "Waikato" },
  { postcode: "4377", code: "TKI", region: "Taranaki" },
  { postcode: "4378", code: "TKI", region: "Taranaki" },
  { postcode: "4379", code: "TKI", region: "Taranaki" },
  { postcode: "4381", code: "TKI", region: "Taranaki" },
  { postcode: "4382", code: "TKI", region: "Taranaki" },
  { postcode: "4383", code: "TKI", region: "Taranaki" },
  { postcode: "4386", code: "TKI", region: "Taranaki" },
  { postcode: "4387", code: "TKI", region: "Taranaki" },
  { postcode: "4388", code: "TKI", region: "Taranaki" },
  { postcode: "4389", code: "TKI", region: "Taranaki" },
  { postcode: "4390", code: "TKI", region: "Taranaki" },
  { postcode: "4391", code: "TKI", region: "Taranaki" },
  { postcode: "4392", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4393", code: "TKI", region: "Taranaki" },
  { postcode: "4394", code: "TKI", region: "Taranaki" },
  { postcode: "4395", code: "TKI", region: "Taranaki" },
  { postcode: "4396", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4397", code: "TKI", region: "Taranaki" },
  { postcode: "4398", code: "TKI", region: "Taranaki" },
  { postcode: "4399", code: "TKI", region: "Taranaki" },
  { postcode: "4410", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4412", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4414", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4440", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4441", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4442", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4443", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4444", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4445", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4446", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4448", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4470", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4471", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4473", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4474", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4475", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4476", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4477", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4478", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4479", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4481", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4500", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4501", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4510", code: "TKI", region: "Taranaki" },
  { postcode: "4520", code: "TKI", region: "Taranaki" },
  { postcode: "4540", code: "TKI", region: "Taranaki" },
  { postcode: "4541", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4542", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4543", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4544", code: "TKI", region: "Taranaki" },
  { postcode: "4545", code: "TKI", region: "Taranaki" },
  { postcode: "4547", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4548", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4549", code: "TKI", region: "Taranaki" },
  { postcode: "4571", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4572", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4573", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4574", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4575", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4576", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4577", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4578", code: "TKI", region: "Taranaki" },
  { postcode: "4581", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4582", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4584", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4585", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4586", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4587", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4588", code: "TKI", region: "Taranaki" },
  { postcode: "4591", code: "TKI", region: "Taranaki" },
  { postcode: "4592", code: "TKI", region: "Taranaki" },
  { postcode: "4597", code: "TKI", region: "Taranaki" },
  { postcode: "4598", code: "TKI", region: "Taranaki" },
  { postcode: "4610", code: "TKI", region: "Taranaki" },
  { postcode: "4612", code: "TKI", region: "Taranaki" },
  { postcode: "4614", code: "TKI", region: "Taranaki" },
  { postcode: "4616", code: "TKI", region: "Taranaki" },
  { postcode: "4625", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4632", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4640", code: "TKI", region: "Taranaki" },
  { postcode: "4641", code: "TKI", region: "Taranaki" },
  { postcode: "4642", code: "TKI", region: "Taranaki" },
  { postcode: "4645", code: "TKI", region: "Taranaki" },
  { postcode: "4646", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4660", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4671", code: "TKI", region: "Taranaki" },
  { postcode: "4672", code: "TKI", region: "Taranaki" },
  { postcode: "4673", code: "TKI", region: "Taranaki" },
  { postcode: "4674", code: "TKI", region: "Taranaki" },
  { postcode: "4675", code: "TKI", region: "Taranaki" },
  { postcode: "4678", code: "TKI", region: "Taranaki" },
  { postcode: "4679", code: "TKI", region: "Taranaki" },
  { postcode: "4681", code: "TKI", region: "Taranaki" },
  { postcode: "4682", code: "TKI", region: "Taranaki" },
  { postcode: "4684", code: "TKI", region: "Taranaki" },
  { postcode: "4685", code: "TKI", region: "Taranaki" },
  { postcode: "4691", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4694", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4696", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4702", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4710", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4720", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4730", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4740", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4741", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4742", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4744", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4745", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4746", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4771", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4774", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4775", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4777", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4779", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4780", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4781", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4782", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4783", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4784", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4785", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4786", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4787", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4788", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4789", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4791", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4792", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4793", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4794", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4795", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4796", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4797", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4810", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4814", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4815", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4816", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4817", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4818", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4820", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4821", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4822", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4825", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4826", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4847", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4848", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4849", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4860", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4861", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4862", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4863", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4864", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4865", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4866", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4867", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4884", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4891", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4893", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4894", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4900", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4910", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4920", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4930", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4940", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4941", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4942", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4943", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4944", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4945", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4970", code: "HKB", region: "Hawke's Bay" },
  { postcode: "4971", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4972", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4973", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4974", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4975", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4976", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4977", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4978", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4979", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4981", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4982", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4983", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4984", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4985", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4986", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4987", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4988", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4989", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4990", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4991", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4992", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4993", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4994", code: "WGN", region: "Wellington" },
  { postcode: "4995", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4996", code: "WGN", region: "Wellington" },
  { postcode: "4997", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4998", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "4999", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "5010", code: "WGN", region: "Wellington" },
  { postcode: "5011", code: "WGN", region: "Wellington" },
  { postcode: "5012", code: "WGN", region: "Wellington" },
  { postcode: "5013", code: "WGN", region: "Wellington" },
  { postcode: "5014", code: "WGN", region: "Wellington" },
  { postcode: "5016", code: "WGN", region: "Wellington" },
  { postcode: "5018", code: "WGN", region: "Wellington" },
  { postcode: "5019", code: "WGN", region: "Wellington" },
  { postcode: "5022", code: "WGN", region: "Wellington" },
  { postcode: "5024", code: "WGN", region: "Wellington" },
  { postcode: "5026", code: "WGN", region: "Wellington" },
  { postcode: "5028", code: "WGN", region: "Wellington" },
  { postcode: "5032", code: "WGN", region: "Wellington" },
  { postcode: "5034", code: "WGN", region: "Wellington" },
  { postcode: "5036", code: "WGN", region: "Wellington" },
  { postcode: "5040", code: "WGN", region: "Wellington" },
  { postcode: "5041", code: "WGN", region: "Wellington" },
  { postcode: "5042", code: "WGN", region: "Wellington" },
  { postcode: "5043", code: "WGN", region: "Wellington" },
  { postcode: "5044", code: "WGN", region: "Wellington" },
  { postcode: "5045", code: "WGN", region: "Wellington" },
  { postcode: "5046", code: "WGN", region: "Wellington" },
  { postcode: "5047", code: "WGN", region: "Wellington" },
  { postcode: "5048", code: "WGN", region: "Wellington" },
  { postcode: "5049", code: "WGN", region: "Wellington" },
  { postcode: "5050", code: "WGN", region: "Wellington" },
  { postcode: "5140", code: "WGN", region: "Wellington" },
  { postcode: "5141", code: "WGN", region: "Wellington" },
  { postcode: "5142", code: "WGN", region: "Wellington" },
  { postcode: "5143", code: "WGN", region: "Wellington" },
  { postcode: "5240", code: "WGN", region: "Wellington" },
  { postcode: "5242", code: "WGN", region: "Wellington" },
  { postcode: "5243", code: "WGN", region: "Wellington" },
  { postcode: "5245", code: "WGN", region: "Wellington" },
  { postcode: "5247", code: "WGN", region: "Wellington" },
  { postcode: "5249", code: "WGN", region: "Wellington" },
  { postcode: "5250", code: "WGN", region: "Wellington" },
  { postcode: "5252", code: "WGN", region: "Wellington" },
  { postcode: "5254", code: "WGN", region: "Wellington" },
  { postcode: "5255", code: "WGN", region: "Wellington" },
  { postcode: "5258", code: "WGN", region: "Wellington" },
  { postcode: "5371", code: "WGN", region: "Wellington" },
  { postcode: "5372", code: "WGN", region: "Wellington" },
  { postcode: "5373", code: "WGN", region: "Wellington" },
  { postcode: "5381", code: "WGN", region: "Wellington" },
  { postcode: "5391", code: "WGN", region: "Wellington" },
  { postcode: "5510", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "5512", code: "WGN", region: "Wellington" },
  { postcode: "5540", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "5541", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "5542", code: "WGN", region: "Wellington" },
  { postcode: "5543", code: "WGN", region: "Wellington" },
  { postcode: "5544", code: "WGN", region: "Wellington" },
  { postcode: "5570", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "5571", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "5572", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "5573", code: "WGN", region: "Wellington" },
  { postcode: "5574", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "5575", code: "MWT", region: "Manawatū-Whanganui" },
  { postcode: "5581", code: "WGN", region: "Wellington" },
  { postcode: "5710", code: "WGN", region: "Wellington" },
  { postcode: "5711", code: "WGN", region: "Wellington" },
  { postcode: "5712", code: "WGN", region: "Wellington" },
  { postcode: "5713", code: "WGN", region: "Wellington" },
  { postcode: "5740", code: "WGN", region: "Wellington" },
  { postcode: "5741", code: "WGN", region: "Wellington" },
  { postcode: "5742", code: "WGN", region: "Wellington" },
  { postcode: "5743", code: "WGN", region: "Wellington" },
  { postcode: "5771", code: "WGN", region: "Wellington" },
  { postcode: "5772", code: "WGN", region: "Wellington" },
  { postcode: "5773", code: "WGN", region: "Wellington" },
  { postcode: "5781", code: "WGN", region: "Wellington" },
  { postcode: "5782", code: "WGN", region: "Wellington" },
  { postcode: "5783", code: "WGN", region: "Wellington" },
  { postcode: "5784", code: "WGN", region: "Wellington" },
  { postcode: "5791", code: "WGN", region: "Wellington" },
  { postcode: "5792", code: "WGN", region: "Wellington" },
  { postcode: "5794", code: "WGN", region: "Wellington" },
  { postcode: "5810", code: "WGN", region: "Wellington" },
  { postcode: "5840", code: "WGN", region: "Wellington" },
  { postcode: "5842", code: "WGN", region: "Wellington" },
  { postcode: "5871", code: "WGN", region: "Wellington" },
  { postcode: "5872", code: "WGN", region: "Wellington" },
  { postcode: "5881", code: "WGN", region: "Wellington" },
  { postcode: "5882", code: "WGN", region: "Wellington" },
  { postcode: "5883", code: "WGN", region: "Wellington" },
  { postcode: "5884", code: "WGN", region: "Wellington" },
  { postcode: "5885", code: "WGN", region: "Wellington" },
  { postcode: "5886", code: "WGN", region: "Wellington" },
  { postcode: "5887", code: "WGN", region: "Wellington" },
  { postcode: "5888", code: "WGN", region: "Wellington" },
  { postcode: "5889", code: "WGN", region: "Wellington" },
  { postcode: "5890", code: "WGN", region: "Wellington" },
  { postcode: "5894", code: "WGN", region: "Wellington" },
  { postcode: "6011", code: "WGN", region: "Wellington" },
  { postcode: "6012", code: "WGN", region: "Wellington" },
  { postcode: "6021", code: "WGN", region: "Wellington" },
  { postcode: "6022", code: "WGN", region: "Wellington" },
  { postcode: "6023", code: "WGN", region: "Wellington" },
  { postcode: "6035", code: "WGN", region: "Wellington" },
  { postcode: "6037", code: "WGN", region: "Wellington" },
  { postcode: "6140", code: "WGN", region: "Wellington" },
  { postcode: "6141", code: "WGN", region: "Wellington" },
  { postcode: "6142", code: "WGN", region: "Wellington" },
  { postcode: "6143", code: "WGN", region: "Wellington" },
  { postcode: "6144", code: "WGN", region: "Wellington" },
  { postcode: "6145", code: "WGN", region: "Wellington" },
  { postcode: "6146", code: "WGN", region: "Wellington" },
  { postcode: "6147", code: "WGN", region: "Wellington" },
  { postcode: "6148", code: "WGN", region: "Wellington" },
  { postcode: "6149", code: "WGN", region: "Wellington" },
  { postcode: "6150", code: "WGN", region: "Wellington" },
  { postcode: "6160", code: "WGN", region: "Wellington" },
  { postcode: "6241", code: "WGN", region: "Wellington" },
  { postcode: "6242", code: "WGN", region: "Wellington" },
  { postcode: "6243", code: "WGN", region: "Wellington" },
  { postcode: "6244", code: "WGN", region: "Wellington" },
  { postcode: "6440", code: "WGN", region: "Wellington" },
  { postcode: "6441", code: "WGN", region: "Wellington" },
  { postcode: "6442", code: "WGN", region: "Wellington" },
  { postcode: "6443", code: "WGN", region: "Wellington" },
  { postcode: "6972", code: "WGN", region: "Wellington" },
  { postcode: "7005", code: "TAS", region: "Tasman" },
  { postcode: "7007", code: "TAS", region: "Tasman" },
  { postcode: "7010", code: "NSN", region: "Nelson" },
  { postcode: "7011", code: "NSN", region: "Nelson" },
  { postcode: "7020", code: "NSN", region: "Nelson" },
  { postcode: "7022", code: "TAS", region: "Tasman" },
  { postcode: "7025", code: "TAS", region: "Tasman" },
  { postcode: "7040", code: "NSN", region: "Nelson" },
  { postcode: "7041", code: "NSN", region: "Nelson" },
  { postcode: "7042", code: "NSN", region: "Nelson" },
  { postcode: "7043", code: "NSN", region: "Nelson" },
  { postcode: "7044", code: "NSN", region: "Nelson" },
  { postcode: "7045", code: "NSN", region: "Nelson" },
  { postcode: "7046", code: "NSN", region: "Nelson" },
  { postcode: "7047", code: "NSN", region: "Nelson" },
  { postcode: "7048", code: "TAS", region: "Tasman" },
  { postcode: "7049", code: "TAS", region: "Tasman" },
  { postcode: "7050", code: "TAS", region: "Tasman" },
  { postcode: "7051", code: "TAS", region: "Tasman" },
  { postcode: "7052", code: "TAS", region: "Tasman" },
  { postcode: "7053", code: "TAS", region: "Tasman" },
  { postcode: "7054", code: "TAS", region: "Tasman" },
  { postcode: "7055", code: "TAS", region: "Tasman" },
  { postcode: "7071", code: "NSN", region: "Nelson" },
  { postcode: "7072", code: "TAS", region: "Tasman" },
  { postcode: "7073", code: "TAS", region: "Tasman" },
  { postcode: "7077", code: "TAS", region: "Tasman" },
  { postcode: "7081", code: "NSN", region: "Nelson" },
  { postcode: "7091", code: "TAS", region: "Tasman" },
  { postcode: "7095", code: "TAS", region: "Tasman" },
  { postcode: "7096", code: "TAS", region: "Tasman" },
  { postcode: "7100", code: "MBH", region: "Marlborough" },
  { postcode: "7110", code: "TAS", region: "Tasman" },
  { postcode: "7120", code: "TAS", region: "Tasman" },
  { postcode: "7142", code: "TAS", region: "Tasman" },
  { postcode: "7143", code: "TAS", region: "Tasman" },
  { postcode: "7144", code: "TAS", region: "Tasman" },
  { postcode: "7145", code: "MBH", region: "Marlborough" },
  { postcode: "7146", code: "TAS", region: "Tasman" },
  { postcode: "7150", code: "MBH", region: "Marlborough" },
  { postcode: "7173", code: "TAS", region: "Tasman" },
  { postcode: "7175", code: "TAS", region: "Tasman" },
  { postcode: "7178", code: "MBH", region: "Marlborough" },
  { postcode: "7182", code: "TAS", region: "Tasman" },
  { postcode: "7183", code: "TAS", region: "Tasman" },
  { postcode: "7192", code: "MBH", region: "Marlborough" },
  { postcode: "7193", code: "MBH", region: "Marlborough" },
  { postcode: "7194", code: "MBH", region: "Marlborough" },
  { postcode: "7195", code: "MBH", region: "Marlborough" },
  { postcode: "7196", code: "TAS", region: "Tasman" },
  { postcode: "7197", code: "TAS", region: "Tasman" },
  { postcode: "7198", code: "TAS", region: "Tasman" },
  { postcode: "7201", code: "MBH", region: "Marlborough" },
  { postcode: "7202", code: "MBH", region: "Marlborough" },
  { postcode: "7204", code: "MBH", region: "Marlborough" },
  { postcode: "7206", code: "MBH", region: "Marlborough" },
  { postcode: "7210", code: "MBH", region: "Marlborough" },
  { postcode: "7220", code: "MBH", region: "Marlborough" },
  { postcode: "7240", code: "MBH", region: "Marlborough" },
  { postcode: "7241", code: "MBH", region: "Marlborough" },
  { postcode: "7242", code: "MBH", region: "Marlborough" },
  { postcode: "7243", code: "MBH", region: "Marlborough" },
  { postcode: "7244", code: "MBH", region: "Marlborough" },
  { postcode: "7246", code: "MBH", region: "Marlborough" },
  { postcode: "7247", code: "MBH", region: "Marlborough" },
  { postcode: "7248", code: "MBH", region: "Marlborough" },
  { postcode: "7250", code: "MBH", region: "Marlborough" },
  { postcode: "7251", code: "MBH", region: "Marlborough" },
  { postcode: "7260", code: "CAN", region: "Canterbury" },
  { postcode: "7271", code: "MBH", region: "Marlborough" },
  { postcode: "7272", code: "MBH", region: "Marlborough" },
  { postcode: "7273", code: "MBH", region: "Marlborough" },
  { postcode: "7274", code: "MBH", region: "Marlborough" },
  { postcode: "7275", code: "MBH", region: "Marlborough" },
  { postcode: "7276", code: "MBH", region: "Marlborough" },
  { postcode: "7281", code: "MBH", region: "Marlborough" },
  { postcode: "7282", code: "MBH", region: "Marlborough" },
  { postcode: "7284", code: "MBH", region: "Marlborough" },
  { postcode: "7285", code: "MBH", region: "Marlborough" },
  { postcode: "7300", code: "CAN", region: "Canterbury" },
  { postcode: "7310", code: "CAN", region: "Canterbury" },
  { postcode: "7332", code: "CAN", region: "Canterbury" },
  { postcode: "7334", code: "CAN", region: "Canterbury" },
  { postcode: "7340", code: "CAN", region: "Canterbury" },
  { postcode: "7341", code: "CAN", region: "Canterbury" },
  { postcode: "7343", code: "CAN", region: "Canterbury" },
  { postcode: "7345", code: "CAN", region: "Canterbury" },
  { postcode: "7348", code: "CAN", region: "Canterbury" },
  { postcode: "7360", code: "CAN", region: "Canterbury" },
  { postcode: "7371", code: "CAN", region: "Canterbury" },
  { postcode: "7373", code: "CAN", region: "Canterbury" },
  { postcode: "7374", code: "CAN", region: "Canterbury" },
  { postcode: "7379", code: "CAN", region: "Canterbury" },
  { postcode: "7381", code: "CAN", region: "Canterbury" },
  { postcode: "7382", code: "CAN", region: "Canterbury" },
  { postcode: "7383", code: "CAN", region: "Canterbury" },
  { postcode: "7384", code: "CAN", region: "Canterbury" },
  { postcode: "7385", code: "CAN", region: "Canterbury" },
  { postcode: "7387", code: "CAN", region: "Canterbury" },
  { postcode: "7391", code: "CAN", region: "Canterbury" },
  { postcode: "7392", code: "CAN", region: "Canterbury" },
  { postcode: "7395", code: "CAN", region: "Canterbury" },
  { postcode: "7400", code: "CAN", region: "Canterbury" },
  { postcode: "7402", code: "CAN", region: "Canterbury" },
  { postcode: "7410", code: "CAN", region: "Canterbury" },
  { postcode: "7420", code: "CAN", region: "Canterbury" },
  { postcode: "7430", code: "CAN", region: "Canterbury" },
  { postcode: "7440", code: "CAN", region: "Canterbury" },
  { postcode: "7441", code: "CAN", region: "Canterbury" },
  { postcode: "7442", code: "CAN", region: "Canterbury" },
  { postcode: "7443", code: "CAN", region: "Canterbury" },
  { postcode: "7444", code: "CAN", region: "Canterbury" },
  { postcode: "7445", code: "CAN", region: "Canterbury" },
  { postcode: "7446", code: "CAN", region: "Canterbury" },
  { postcode: "7447", code: "CAN", region: "Canterbury" },
  { postcode: "7448", code: "CAN", region: "Canterbury" },
  { postcode: "7471", code: "CAN", region: "Canterbury" },
  { postcode: "7472", code: "CAN", region: "Canterbury" },
  { postcode: "7473", code: "CAN", region: "Canterbury" },
  { postcode: "7474", code: "CAN", region: "Canterbury" },
  { postcode: "7481", code: "CAN", region: "Canterbury" },
  { postcode: "7482", code: "CAN", region: "Canterbury" },
  { postcode: "7483", code: "CAN", region: "Canterbury" },
  { postcode: "7491", code: "CAN", region: "Canterbury" },
  { postcode: "7495", code: "CAN", region: "Canterbury" },
  { postcode: "7500", code: "CAN", region: "Canterbury" },
  { postcode: "7510", code: "CAN", region: "Canterbury" },
  { postcode: "7520", code: "CAN", region: "Canterbury" },
  { postcode: "7540", code: "CAN", region: "Canterbury" },
  { postcode: "7541", code: "CAN", region: "Canterbury" },
  { postcode: "7542", code: "CAN", region: "Canterbury" },
  { postcode: "7543", code: "CAN", region: "Canterbury" },
  { postcode: "7544", code: "CAN", region: "Canterbury" },
  { postcode: "7545", code: "CAN", region: "Canterbury" },
  { postcode: "7546", code: "CAN", region: "Canterbury" },
  { postcode: "7548", code: "CAN", region: "Canterbury" },
  { postcode: "7571", code: "CAN", region: "Canterbury" },
  { postcode: "7572", code: "CAN", region: "Canterbury" },
  { postcode: "7580", code: "CAN", region: "Canterbury" },
  { postcode: "7581", code: "CAN", region: "Canterbury" },
  { postcode: "7582", code: "CAN", region: "Canterbury" },
  { postcode: "7583", code: "CAN", region: "Canterbury" },
  { postcode: "7591", code: "CAN", region: "Canterbury" },
  { postcode: "7600", code: "CAN", region: "Canterbury" },
  { postcode: "7602", code: "CAN", region: "Canterbury" },
  { postcode: "7604", code: "CAN", region: "Canterbury" },
  { postcode: "7608", code: "CAN", region: "Canterbury" },
  { postcode: "7610", code: "CAN", region: "Canterbury" },
  { postcode: "7611", code: "CAN", region: "Canterbury" },
  { postcode: "7612", code: "CAN", region: "Canterbury" },
  { postcode: "7613", code: "CAN", region: "Canterbury" },
  { postcode: "7614", code: "CAN", region: "Canterbury" },
  { postcode: "7616", code: "CAN", region: "Canterbury" },
  { postcode: "7630", code: "CAN", region: "Canterbury" },
  { postcode: "7632", code: "CAN", region: "Canterbury" },
  { postcode: "7640", code: "CAN", region: "Canterbury" },
  { postcode: "7641", code: "CAN", region: "Canterbury" },
  { postcode: "7642", code: "CAN", region: "Canterbury" },
  { postcode: "7643", code: "CAN", region: "Canterbury" },
  { postcode: "7644", code: "CAN", region: "Canterbury" },
  { postcode: "7645", code: "CAN", region: "Canterbury" },
  { postcode: "7646", code: "CAN", region: "Canterbury" },
  { postcode: "7647", code: "CAN", region: "Canterbury" },
  { postcode: "7649", code: "CAN", region: "Canterbury" },
  { postcode: "7656", code: "CAN", region: "Canterbury" },
  { postcode: "7657", code: "CAN", region: "Canterbury" },
  { postcode: "7670", code: "CAN", region: "Canterbury" },
  { postcode: "7671", code: "CAN", region: "Canterbury" },
  { postcode: "7672", code: "CAN", region: "Canterbury" },
  { postcode: "7673", code: "CAN", region: "Canterbury" },
  { postcode: "7674", code: "CAN", region: "Canterbury" },
  { postcode: "7675", code: "CAN", region: "Canterbury" },
  { postcode: "7676", code: "CAN", region: "Canterbury" },
  { postcode: "7677", code: "CAN", region: "Canterbury" },
  { postcode: "7678", code: "CAN", region: "Canterbury" },
  { postcode: "7681", code: "CAN", region: "Canterbury" },
  { postcode: "7682", code: "CAN", region: "Canterbury" },
  { postcode: "7683", code: "CAN", region: "Canterbury" },
  { postcode: "7691", code: "CAN", region: "Canterbury" },
  { postcode: "7692", code: "CAN", region: "Canterbury" },
  { postcode: "7700", code: "CAN", region: "Canterbury" },
  { postcode: "7710", code: "CAN", region: "Canterbury" },
  { postcode: "7730", code: "CAN", region: "Canterbury" },
  { postcode: "7740", code: "CAN", region: "Canterbury" },
  { postcode: "7741", code: "CAN", region: "Canterbury" },
  { postcode: "7742", code: "CAN", region: "Canterbury" },
  { postcode: "7743", code: "CAN", region: "Canterbury" },
  { postcode: "7745", code: "CAN", region: "Canterbury" },
  { postcode: "7746", code: "CAN", region: "Canterbury" },
  { postcode: "7747", code: "CAN", region: "Canterbury" },
  { postcode: "7748", code: "CAN", region: "Canterbury" },
  { postcode: "7771", code: "CAN", region: "Canterbury" },
  { postcode: "7772", code: "CAN", region: "Canterbury" },
  { postcode: "7773", code: "CAN", region: "Canterbury" },
  { postcode: "7774", code: "CAN", region: "Canterbury" },
  { postcode: "7775", code: "CAN", region: "Canterbury" },
  { postcode: "7776", code: "CAN", region: "Canterbury" },
  { postcode: "7777", code: "CAN", region: "Canterbury" },
  { postcode: "7778", code: "CAN", region: "Canterbury" },
  { postcode: "7781", code: "CAN", region: "Canterbury" },
  { postcode: "7782", code: "CAN", region: "Canterbury" },
  { postcode: "7783", code: "CAN", region: "Canterbury" },
  { postcode: "7784", code: "CAN", region: "Canterbury" },
  { postcode: "7791", code: "CAN", region: "Canterbury" },
  { postcode: "7802", code: "WTC", region: "West Coast" },
  { postcode: "7803", code: "WTC", region: "West Coast" },
  { postcode: "7804", code: "WTC", region: "West Coast" },
  { postcode: "7805", code: "WTC", region: "West Coast" },
  { postcode: "7810", code: "WTC", region: "West Coast" },
  { postcode: "7811", code: "WTC", region: "West Coast" },
  { postcode: "7812", code: "WTC", region: "West Coast" },
  { postcode: "7822", code: "WTC", region: "West Coast" },
  { postcode: "7823", code: "WTC", region: "West Coast" },
  { postcode: "7825", code: "WTC", region: "West Coast" },
  { postcode: "7830", code: "WTC", region: "West Coast" },
  { postcode: "7832", code: "WTC", region: "West Coast" },
  { postcode: "7840", code: "WTC", region: "West Coast" },
  { postcode: "7841", code: "WTC", region: "West Coast" },
  { postcode: "7842", code: "WTC", region: "West Coast" },
  { postcode: "7843", code: "WTC", region: "West Coast" },
  { postcode: "7844", code: "WTC", region: "West Coast" },
  { postcode: "7845", code: "WTC", region: "West Coast" },
  { postcode: "7846", code: "WTC", region: "West Coast" },
  { postcode: "7847", code: "WTC", region: "West Coast" },
  { postcode: "7848", code: "WTC", region: "West Coast" },
  { postcode: "7851", code: "WTC", region: "West Coast" },
  { postcode: "7856", code: "WTC", region: "West Coast" },
  { postcode: "7857", code: "WTC", region: "West Coast" },
  { postcode: "7859", code: "WTC", region: "West Coast" },
  { postcode: "7860", code: "WTC", region: "West Coast" },
  { postcode: "7861", code: "WTC", region: "West Coast" },
  { postcode: "7862", code: "WTC", region: "West Coast" },
  { postcode: "7863", code: "WTC", region: "West Coast" },
  { postcode: "7864", code: "WTC", region: "West Coast" },
  { postcode: "7865", code: "WTC", region: "West Coast" },
  { postcode: "7866", code: "WTC", region: "West Coast" },
  { postcode: "7867", code: "WTC", region: "West Coast" },
  { postcode: "7871", code: "WTC", region: "West Coast" },
  { postcode: "7872", code: "WTC", region: "West Coast" },
  { postcode: "7873", code: "WTC", region: "West Coast" },
  { postcode: "7875", code: "WTC", region: "West Coast" },
  { postcode: "7881", code: "WTC", region: "West Coast" },
  { postcode: "7882", code: "WTC", region: "West Coast" },
  { postcode: "7883", code: "WTC", region: "West Coast" },
  { postcode: "7884", code: "WTC", region: "West Coast" },
  { postcode: "7885", code: "WTC", region: "West Coast" },
  { postcode: "7886", code: "WTC", region: "West Coast" },
  { postcode: "7891", code: "WTC", region: "West Coast" },
  { postcode: "7892", code: "WTC", region: "West Coast" },
  { postcode: "7893", code: "WTC", region: "West Coast" },
  { postcode: "7895", code: "WTC", region: "West Coast" },
  { postcode: "7901", code: "CAN", region: "Canterbury" },
  { postcode: "7903", code: "CAN", region: "Canterbury" },
  { postcode: "7910", code: "CAN", region: "Canterbury" },
  { postcode: "7912", code: "CAN", region: "Canterbury" },
  { postcode: "7920", code: "CAN", region: "Canterbury" },
  { postcode: "7924", code: "CAN", region: "Canterbury" },
  { postcode: "7925", code: "CAN", region: "Canterbury" },
  { postcode: "7930", code: "CAN", region: "Canterbury" },
  { postcode: "7940", code: "CAN", region: "Canterbury" },
  { postcode: "7941", code: "CAN", region: "Canterbury" },
  { postcode: "7942", code: "CAN", region: "Canterbury" },
  { postcode: "7943", code: "CAN", region: "Canterbury" },
  { postcode: "7944", code: "CAN", region: "Canterbury" },
  { postcode: "7945", code: "CAN", region: "Canterbury" },
  { postcode: "7946", code: "CAN", region: "Canterbury" },
  { postcode: "7947", code: "CAN", region: "Canterbury" },
  { postcode: "7948", code: "CAN", region: "Canterbury" },
  { postcode: "7949", code: "CAN", region: "Canterbury" },
  { postcode: "7956", code: "CAN", region: "Canterbury" },
  { postcode: "7957", code: "CAN", region: "Canterbury" },
  { postcode: "7958", code: "CAN", region: "Canterbury" },
  { postcode: "7960", code: "CAN", region: "Canterbury" },
  { postcode: "7971", code: "CAN", region: "Canterbury" },
  { postcode: "7972", code: "CAN", region: "Canterbury" },
  { postcode: "7973", code: "CAN", region: "Canterbury" },
  { postcode: "7974", code: "CAN", region: "Canterbury" },
  { postcode: "7977", code: "CAN", region: "Canterbury" },
  { postcode: "7978", code: "CAN", region: "Canterbury" },
  { postcode: "7979", code: "CAN", region: "Canterbury" },
  { postcode: "7980", code: "CAN", region: "Canterbury" },
  { postcode: "7982", code: "CAN", region: "Canterbury" },
  { postcode: "7983", code: "CAN", region: "Canterbury" },
  { postcode: "7984", code: "CAN", region: "Canterbury" },
  { postcode: "7985", code: "CAN", region: "Canterbury" },
  { postcode: "7986", code: "CAN", region: "Canterbury" },
  { postcode: "7987", code: "CAN", region: "Canterbury" },
  { postcode: "7988", code: "CAN", region: "Canterbury" },
  { postcode: "7990", code: "CAN", region: "Canterbury" },
  { postcode: "7991", code: "CAN", region: "Canterbury" },
  { postcode: "7992", code: "CAN", region: "Canterbury" },
  { postcode: "7999", code: "CAN", region: "Canterbury" },
  { postcode: "8011", code: "CAN", region: "Canterbury" },
  { postcode: "8013", code: "CAN", region: "Canterbury" },
  { postcode: "8014", code: "CAN", region: "Canterbury" },
  { postcode: "8022", code: "CAN", region: "Canterbury" },
  { postcode: "8023", code: "CAN", region: "Canterbury" },
  { postcode: "8024", code: "CAN", region: "Canterbury" },
  { postcode: "8025", code: "CAN", region: "Canterbury" },
  { postcode: "8041", code: "CAN", region: "Canterbury" },
  { postcode: "8042", code: "CAN", region: "Canterbury" },
  { postcode: "8051", code: "CAN", region: "Canterbury" },
  { postcode: "8052", code: "CAN", region: "Canterbury" },
  { postcode: "8053", code: "CAN", region: "Canterbury" },
  { postcode: "8061", code: "CAN", region: "Canterbury" },
  { postcode: "8062", code: "CAN", region: "Canterbury" },
  { postcode: "8081", code: "CAN", region: "Canterbury" },
  { postcode: "8082", code: "CAN", region: "Canterbury" },
  { postcode: "8083", code: "CAN", region: "Canterbury" },
  { postcode: "8140", code: "CAN", region: "Canterbury" },
  { postcode: "8141", code: "CAN", region: "Canterbury" },
  { postcode: "8142", code: "CAN", region: "Canterbury" },
  { postcode: "8143", code: "CAN", region: "Canterbury" },
  { postcode: "8144", code: "CAN", region: "Canterbury" },
  { postcode: "8145", code: "CAN", region: "Canterbury" },
  { postcode: "8146", code: "CAN", region: "Canterbury" },
  { postcode: "8147", code: "CAN", region: "Canterbury" },
  { postcode: "8148", code: "CAN", region: "Canterbury" },
  { postcode: "8149", code: "CAN", region: "Canterbury" },
  { postcode: "8240", code: "CAN", region: "Canterbury" },
  { postcode: "8241", code: "CAN", region: "Canterbury" },
  { postcode: "8242", code: "CAN", region: "Canterbury" },
  { postcode: "8243", code: "CAN", region: "Canterbury" },
  { postcode: "8244", code: "CAN", region: "Canterbury" },
  { postcode: "8245", code: "CAN", region: "Canterbury" },
  { postcode: "8246", code: "CAN", region: "Canterbury" },
  { postcode: "8247", code: "CAN", region: "Canterbury" },
  { postcode: "8440", code: "CAN", region: "Canterbury" },
  { postcode: "8441", code: "CAN", region: "Canterbury" },
  { postcode: "8442", code: "CAN", region: "Canterbury" },
  { postcode: "8443", code: "CAN", region: "Canterbury" },
  { postcode: "8444", code: "CAN", region: "Canterbury" },
  { postcode: "8445", code: "CAN", region: "Canterbury" },
  { postcode: "8540", code: "CAN", region: "Canterbury" },
  { postcode: "8542", code: "CAN", region: "Canterbury" },
  { postcode: "8543", code: "CAN", region: "Canterbury" },
  { postcode: "8544", code: "CAN", region: "Canterbury" },
  { postcode: "8545", code: "CAN", region: "Canterbury" },
  { postcode: "8546", code: "CAN", region: "Canterbury" },
  { postcode: "8640", code: "CAN", region: "Canterbury" },
  { postcode: "8641", code: "CAN", region: "Canterbury" },
  { postcode: "8642", code: "CAN", region: "Canterbury" },
  { postcode: "8643", code: "CAN", region: "Canterbury" },
  { postcode: "8840", code: "CAN", region: "Canterbury" },
  { postcode: "8841", code: "CAN", region: "Canterbury" },
  { postcode: "8842", code: "CAN", region: "Canterbury" },
  { postcode: "8941", code: "CAN", region: "Canterbury" },
  { postcode: "8942", code: "CAN", region: "Canterbury" },
  { postcode: "8971", code: "CAN", region: "Canterbury" },
  { postcode: "8972", code: "CAN", region: "Canterbury" },
  { postcode: "9010", code: "OTA", region: "Otago" },
  { postcode: "9011", code: "OTA", region: "Otago" },
  { postcode: "9012", code: "OTA", region: "Otago" },
  { postcode: "9013", code: "OTA", region: "Otago" },
  { postcode: "9014", code: "OTA", region: "Otago" },
  { postcode: "9016", code: "OTA", region: "Otago" },
  { postcode: "9018", code: "OTA", region: "Otago" },
  { postcode: "9019", code: "OTA", region: "Otago" },
  { postcode: "9022", code: "OTA", region: "Otago" },
  { postcode: "9023", code: "OTA", region: "Otago" },
  { postcode: "9024", code: "OTA", region: "Otago" },
  { postcode: "9035", code: "OTA", region: "Otago" },
  { postcode: "9040", code: "OTA", region: "Otago" },
  { postcode: "9041", code: "OTA", region: "Otago" },
  { postcode: "9042", code: "OTA", region: "Otago" },
  { postcode: "9043", code: "OTA", region: "Otago" },
  { postcode: "9044", code: "OTA", region: "Otago" },
  { postcode: "9045", code: "OTA", region: "Otago" },
  { postcode: "9046", code: "OTA", region: "Otago" },
  { postcode: "9047", code: "OTA", region: "Otago" },
  { postcode: "9048", code: "OTA", region: "Otago" },
  { postcode: "9049", code: "OTA", region: "Otago" },
  { postcode: "9050", code: "OTA", region: "Otago" },
  { postcode: "9052", code: "OTA", region: "Otago" },
  { postcode: "9053", code: "OTA", region: "Otago" },
  { postcode: "9054", code: "OTA", region: "Otago" },
  { postcode: "9058", code: "OTA", region: "Otago" },
  { postcode: "9059", code: "OTA", region: "Otago" },
  { postcode: "9062", code: "OTA", region: "Otago" },
  { postcode: "9067", code: "OTA", region: "Otago" },
  { postcode: "9069", code: "OTA", region: "Otago" },
  { postcode: "9073", code: "OTA", region: "Otago" },
  { postcode: "9074", code: "OTA", region: "Otago" },
  { postcode: "9076", code: "OTA", region: "Otago" },
  { postcode: "9077", code: "OTA", region: "Otago" },
  { postcode: "9081", code: "OTA", region: "Otago" },
  { postcode: "9082", code: "OTA", region: "Otago" },
  { postcode: "9085", code: "OTA", region: "Otago" },
  { postcode: "9091", code: "OTA", region: "Otago" },
  { postcode: "9092", code: "OTA", region: "Otago" },
  { postcode: "9210", code: "OTA", region: "Otago" },
  { postcode: "9220", code: "OTA", region: "Otago" },
  { postcode: "9230", code: "OTA", region: "Otago" },
  { postcode: "9231", code: "OTA", region: "Otago" },
  { postcode: "9240", code: "OTA", region: "Otago" },
  { postcode: "9241", code: "OTA", region: "Otago" },
  { postcode: "9242", code: "OTA", region: "Otago" },
  { postcode: "9271", code: "OTA", region: "Otago" },
  { postcode: "9272", code: "OTA", region: "Otago" },
  { postcode: "9273", code: "OTA", region: "Otago" },
  { postcode: "9274", code: "OTA", region: "Otago" },
  { postcode: "9281", code: "OTA", region: "Otago" },
  { postcode: "9282", code: "OTA", region: "Otago" },
  { postcode: "9291", code: "OTA", region: "Otago" },
  { postcode: "9292", code: "OTA", region: "Otago" },
  { postcode: "9300", code: "OTA", region: "Otago" },
  { postcode: "9302", code: "OTA", region: "Otago" },
  { postcode: "9304", code: "OTA", region: "Otago" },
  { postcode: "9305", code: "OTA", region: "Otago" },
  { postcode: "9310", code: "OTA", region: "Otago" },
  { postcode: "9320", code: "OTA", region: "Otago" },
  { postcode: "9330", code: "OTA", region: "Otago" },
  { postcode: "9332", code: "OTA", region: "Otago" },
  { postcode: "9340", code: "OTA", region: "Otago" },
  { postcode: "9341", code: "OTA", region: "Otago" },
  { postcode: "9342", code: "OTA", region: "Otago" },
  { postcode: "9343", code: "OTA", region: "Otago" },
  { postcode: "9344", code: "OTA", region: "Otago" },
  { postcode: "9345", code: "OTA", region: "Otago" },
  { postcode: "9346", code: "OTA", region: "Otago" },
  { postcode: "9347", code: "OTA", region: "Otago" },
  { postcode: "9348", code: "OTA", region: "Otago" },
  { postcode: "9349", code: "OTA", region: "Otago" },
  { postcode: "9350", code: "OTA", region: "Otago" },
  { postcode: "9351", code: "OTA", region: "Otago" },
  { postcode: "9352", code: "OTA", region: "Otago" },
  { postcode: "9353", code: "OTA", region: "Otago" },
  { postcode: "9354", code: "OTA", region: "Otago" },
  { postcode: "9355", code: "OTA", region: "Otago" },
  { postcode: "9356", code: "STL", region: "Southland" },
  { postcode: "9371", code: "OTA", region: "Otago" },
  { postcode: "9372", code: "OTA", region: "Otago" },
  { postcode: "9376", code: "OTA", region: "Otago" },
  { postcode: "9377", code: "OTA", region: "Otago" },
  { postcode: "9381", code: "OTA", region: "Otago" },
  { postcode: "9382", code: "OTA", region: "Otago" },
  { postcode: "9383", code: "OTA", region: "Otago" },
  { postcode: "9384", code: "OTA", region: "Otago" },
  { postcode: "9386", code: "OTA", region: "Otago" },
  { postcode: "9387", code: "OTA", region: "Otago" },
  { postcode: "9391", code: "OTA", region: "Otago" },
  { postcode: "9392", code: "OTA", region: "Otago" },
  { postcode: "9393", code: "OTA", region: "Otago" },
  { postcode: "9395", code: "OTA", region: "Otago" },
  { postcode: "9396", code: "OTA", region: "Otago" },
  { postcode: "9397", code: "OTA", region: "Otago" },
  { postcode: "9398", code: "OTA", region: "Otago" },
  { postcode: "9400", code: "OTA", region: "Otago" },
  { postcode: "9401", code: "OTA", region: "Otago" },
  { postcode: "9410", code: "OTA", region: "Otago" },
  { postcode: "9412", code: "CAN", region: "Canterbury" },
  { postcode: "9430", code: "OTA", region: "Otago" },
  { postcode: "9435", code: "CAN", region: "Canterbury" },
  { postcode: "9441", code: "OTA", region: "Otago" },
  { postcode: "9442", code: "OTA", region: "Otago" },
  { postcode: "9443", code: "OTA", region: "Otago" },
  { postcode: "9444", code: "OTA", region: "Otago" },
  { postcode: "9445", code: "OTA", region: "Otago" },
  { postcode: "9446", code: "CAN", region: "Canterbury" },
  { postcode: "9447", code: "CAN", region: "Canterbury" },
  { postcode: "9448", code: "CAN", region: "Canterbury" },
  { postcode: "9471", code: "OTA", region: "Otago" },
  { postcode: "9472", code: "OTA", region: "Otago" },
  { postcode: "9481", code: "OTA", region: "Otago" },
  { postcode: "9482", code: "OTA", region: "Otago" },
  { postcode: "9483", code: "OTA", region: "Otago" },
  { postcode: "9491", code: "CAN", region: "Canterbury" },
  { postcode: "9492", code: "OTA", region: "Otago" },
  { postcode: "9493", code: "OTA", region: "Otago" },
  { postcode: "9494", code: "OTA", region: "Otago" },
  { postcode: "9495", code: "OTA", region: "Otago" },
  { postcode: "9498", code: "CAN", region: "Canterbury" },
  { postcode: "9500", code: "OTA", region: "Otago" },
  { postcode: "9510", code: "OTA", region: "Otago" },
  { postcode: "9522", code: "OTA", region: "Otago" },
  { postcode: "9532", code: "OTA", region: "Otago" },
  { postcode: "9534", code: "OTA", region: "Otago" },
  { postcode: "9535", code: "OTA", region: "Otago" },
  { postcode: "9541", code: "OTA", region: "Otago" },
  { postcode: "9542", code: "OTA", region: "Otago" },
  { postcode: "9543", code: "OTA", region: "Otago" },
  { postcode: "9544", code: "OTA", region: "Otago" },
  { postcode: "9546", code: "OTA", region: "Otago" },
  { postcode: "9548", code: "OTA", region: "Otago" },
  { postcode: "9571", code: "OTA", region: "Otago" },
  { postcode: "9572", code: "OTA", region: "Otago" },
  { postcode: "9583", code: "OTA", region: "Otago" },
  { postcode: "9584", code: "OTA", region: "Otago" },
  { postcode: "9585", code: "OTA", region: "Otago" },
  { postcode: "9586", code: "OTA", region: "Otago" },
  { postcode: "9587", code: "OTA", region: "Otago" },
  { postcode: "9588", code: "OTA", region: "Otago" },
  { postcode: "9591", code: "OTA", region: "Otago" },
  { postcode: "9593", code: "OTA", region: "Otago" },
  { postcode: "9596", code: "OTA", region: "Otago" },
  { postcode: "9597", code: "OTA", region: "Otago" },
  { postcode: "9598", code: "OTA", region: "Otago" },
  { postcode: "9600", code: "STL", region: "Southland" },
  { postcode: "9610", code: "STL", region: "Southland" },
  { postcode: "9620", code: "STL", region: "Southland" },
  { postcode: "9630", code: "STL", region: "Southland" },
  { postcode: "9635", code: "STL", region: "Southland" },
  { postcode: "9640", code: "STL", region: "Southland" },
  { postcode: "9641", code: "STL", region: "Southland" },
  { postcode: "9642", code: "STL", region: "Southland" },
  { postcode: "9643", code: "STL", region: "Southland" },
  { postcode: "9644", code: "STL", region: "Southland" },
  { postcode: "9645", code: "STL", region: "Southland" },
  { postcode: "9672", code: "STL", region: "Southland" },
  { postcode: "9679", code: "STL", region: "Southland" },
  { postcode: "9682", code: "STL", region: "Southland" },
  { postcode: "9683", code: "STL", region: "Southland" },
  { postcode: "9689", code: "STL", region: "Southland" },
  { postcode: "9691", code: "STL", region: "Southland" },
  { postcode: "9710", code: "STL", region: "Southland" },
  { postcode: "9712", code: "STL", region: "Southland" },
  { postcode: "9720", code: "STL", region: "Southland" },
  { postcode: "9730", code: "STL", region: "Southland" },
  { postcode: "9740", code: "STL", region: "Southland" },
  { postcode: "9741", code: "STL", region: "Southland" },
  { postcode: "9742", code: "STL", region: "Southland" },
  { postcode: "9743", code: "STL", region: "Southland" },
  { postcode: "9744", code: "STL", region: "Southland" },
  { postcode: "9745", code: "STL", region: "Southland" },
  { postcode: "9746", code: "STL", region: "Southland" },
  { postcode: "9747", code: "STL", region: "Southland" },
  { postcode: "9748", code: "OTA", region: "Otago" },
  { postcode: "9749", code: "STL", region: "Southland" },
  { postcode: "9750", code: "STL", region: "Southland" },
  { postcode: "9752", code: "STL", region: "Southland" },
  { postcode: "9771", code: "STL", region: "Southland" },
  { postcode: "9772", code: "STL", region: "Southland" },
  { postcode: "9773", code: "STL", region: "Southland" },
  { postcode: "9774", code: "STL", region: "Southland" },
  { postcode: "9775", code: "STL", region: "Southland" },
  { postcode: "9776", code: "STL", region: "Southland" },
  { postcode: "9777", code: "STL", region: "Southland" },
  { postcode: "9778", code: "STL", region: "Southland" },
  { postcode: "9779", code: "STL", region: "Southland" },
  { postcode: "9781", code: "STL", region: "Southland" },
  { postcode: "9782", code: "STL", region: "Southland" },
  { postcode: "9783", code: "STL", region: "Southland" },
  { postcode: "9791", code: "STL", region: "Southland" },
  { postcode: "9792", code: "STL", region: "Southland" },
  { postcode: "9793", code: "STL", region: "Southland" },
  { postcode: "9794", code: "STL", region: "Southland" },
  { postcode: "9810", code: "STL", region: "Southland" },
  { postcode: "9812", code: "STL", region: "Southland" },
  { postcode: "9814", code: "STL", region: "Southland" },
  { postcode: "9816", code: "STL", region: "Southland" },
  { postcode: "9818", code: "STL", region: "Southland" },
  { postcode: "9822", code: "STL", region: "Southland" },
  { postcode: "9825", code: "STL", region: "Southland" },
  { postcode: "9831", code: "STL", region: "Southland" },
  { postcode: "9840", code: "STL", region: "Southland" },
  { postcode: "9841", code: "STL", region: "Southland" },
  { postcode: "9842", code: "STL", region: "Southland" },
  { postcode: "9843", code: "STL", region: "Southland" },
  { postcode: "9844", code: "STL", region: "Southland" },
  { postcode: "9845", code: "STL", region: "Southland" },
  { postcode: "9846", code: "STL", region: "Southland" },
  { postcode: "9847", code: "STL", region: "Southland" },
  { postcode: "9848", code: "STL", region: "Southland" },
  { postcode: "9849", code: "STL", region: "Southland" },
  { postcode: "9871", code: "STL", region: "Southland" },
  { postcode: "9872", code: "STL", region: "Southland" },
  { postcode: "9874", code: "STL", region: "Southland" },
  { postcode: "9875", code: "STL", region: "Southland" },
  { postcode: "9876", code: "STL", region: "Southland" },
  { postcode: "9877", code: "STL", region: "Southland" },
  { postcode: "9879", code: "STL", region: "Southland" },
  { postcode: "9881", code: "STL", region: "Southland" },
  { postcode: "9883", code: "STL", region: "Southland" },
  { postcode: "9884", code: "STL", region: "Southland" },
  { postcode: "9891", code: "STL", region: "Southland" },
  { postcode: "9892", code: "STL", region: "Southland" },
  { postcode: "9893", code: "STL", region: "Southland" },
];
