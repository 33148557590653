import React, {useState} from 'react';
import {useCartContext} from "../CartContext";

const DiscountCodeForm = () => {
  const {checkout, cartLoading, addDiscountCode} = useCartContext();
  const [discountCode, setDiscountCode] = useState('');
  const [showDiscountCodeForm, setShowDiscountCodeForm] = useState(false);
  const [discountCodeError, setDiscountCodeError] = useState<string | null>(null);

  async function handleDiscountCode(event: React.SyntheticEvent) {
    event.preventDefault();
    setDiscountCode('');
    setDiscountCodeError(null);
    try {
      await addDiscountCode(discountCode);
    } catch {
      setDiscountCodeError(`Invalid discount code`);
    }
  }

  async function removeDiscountCode() {
    setDiscountCode('');
    await addDiscountCode('');
    setShowDiscountCodeForm(false);
    setDiscountCodeError(null);
  }

  if (!checkout) {
    return (<></>);
  }

  return (
    <div>
      {!checkout.discountCode && !showDiscountCodeForm && (
        <button type="button" className="text-left text-blue-400 underline font-medium"
                onClick={() => setShowDiscountCodeForm(true)}>Add discount code</button>
      )}
      {!checkout.discountCode && showDiscountCodeForm && (
        <div>
          <label htmlFor="discount-code" className="block text-sm font-medium text-gray-700">
            Discount code
          </label>
          <div className="mt-1 flex space-x-4">
            <input
              type="text"
              onChange={(event) => {
                setDiscountCodeError(null);
                setDiscountCode(event.target.value ?? '');
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  return handleDiscountCode(event);
                }
              }}
              id="discount-code"
              name="discount-code"
              className="block w-full rounded-full border-gray-300 shadow-sm focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
            />
            <button
              type="button"
              disabled={cartLoading}
              onClick={handleDiscountCode}
              className="rounded-full bg-gray-900 px-4 text-sm font-bold text-white hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-300"
            >
              Apply
            </button>
            <button type="button" onClick={() => setShowDiscountCodeForm(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                   stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
          {discountCodeError && (
            <p className="mt-2 text-sm text-red-600" id="discountCode-error">
              {discountCodeError}
            </p>
          )}
        </div>
      )}

      {checkout.discountCode && (
        <div className="flex items-center justify-flex-end">
          <div
            className="flex items-center shadow-sm justify-center align-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                 stroke="currentColor" className="w-4 h-4 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z"/>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6Z"/>
            </svg>
            <span className="px-2">{checkout.discountCode}</span>
            <button type="button" onClick={() => removeDiscountCode()} disabled={cartLoading}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default DiscountCodeForm;
