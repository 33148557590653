import formatPrice from "../utils/formatPrice";
import React from "react";
import {IBundleItem} from "../types/types";
import {CurrencyCode} from "../types/storefront.generated";

type BundleItemProps = {
  bundle: IBundleItem;
  currencyCode: CurrencyCode;
};
const BundleItem = ({ bundle, currencyCode }: BundleItemProps) => (
  <li key={bundle.id} className="flex items-start space-x-4 py-6">
    <div className="relative">
      <img
        src={bundle?.imageUrl ?? ''}
        alt={bundle?.title ?? ''}
        className="w-20 flex-none rounded-md object-cover object-center"
      />
      <div
        className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-gray-400 rounded-full -top-2 -right-2">{bundle.quantity}</div>
    </div>
    <div className="flex-auto space-y-1 w-20">
      <>
        <h2>{bundle?.title}</h2>
        {bundle.selectedVariants.map((selectedVariant, index) => (
          <h3 key={`${selectedVariant.id}_${index}`} className="font-light text-gray-500">{selectedVariant?.title}</h3>
        ))}
      </>
    </div>
    {bundle.comparePrice && (
      <>
        <p
          className="flex-none text-base font-medium line-through">{formatPrice(bundle?.comparePrice, currencyCode)}</p>
        <p
          className="flex-none text-base font-medium text-gray-900">{formatPrice(bundle?.price, currencyCode)}</p>
      </>
    )}
    {!bundle.comparePrice && (
      <p
        className="flex-none text-base font-medium">{formatPrice(bundle?.price, currencyCode)}</p>
    )}
  </li>
);
export default BundleItem;
