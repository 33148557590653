type CurrencyCode = "NZD" | "GBP" | "USD" | "AUD";

const currencySymbolMap: Record<CurrencyCode, string> = {
  NZD: "$",
  GBP: "£",
  USD: "$",
  AUD: "$",
};

function getCurrencySymbol(currencyCode: CurrencyCode): string {
  return currencySymbolMap[currencyCode] || currencyCode;
}

export default function formatPrice(amount: string, currency: string) {
  const formattedAmount = parseFloat(
    amount,
  ).toLocaleString('en-NZ', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return `${currency}${getCurrencySymbol(currency as CurrencyCode)}${formattedAmount}`;
}
