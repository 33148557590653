import classNames from "../utils/classNames";
import React from "react";

interface ITextInputProps {
  name: string;
  label: string;
  type: string;
  autoComplete?: string | undefined;
  placeholder?: string | undefined;
  value: string;
  hideLabel?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  errors: {[key: string]: string[]};
}
const TextInput = ({name, label, type, autoComplete, placeholder = '', hideLabel  = true, value, errors, onChange, onBlur} : ITextInputProps) => {
  return (
    <div>
      <label htmlFor={name} className={hideLabel ? 'sr-only' : 'block text-sm font-medium text-gray-700'}>
        {label}
      </label>
      <div className="relative mt-2">
        <input
          type={type}
          name={name}
          autoComplete={autoComplete}
          placeholder={placeholder}
          className={classNames(
            "block w-full rounded-full p-3 border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:shadow-outline placeholder:text-gray-400 focus:ring-4 focus:ring-inset sm:text-sm sm:leading-6",
            errors[name] ? "ring-red-300 focus:ring-red-300" : "focus:ring-gray-300"
          )}
          value={value}
          aria-invalid={errors[name] ? "true" : "false"}
          onChange={onChange}
          onBlur={onBlur}
        />
        {errors[name] && type !== 'hidden' && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                 stroke="currentColor" className="h-5 w-5 text-red-500">
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"/>
            </svg>
          </div>
        )}
      </div>
      {errors[name] && type !== 'hidden' && (
        <p className="mt-2 text-sm text-red-600">
          {errors[name]}
        </p>
      )}
    </div>
  )
}

export default TextInput;
