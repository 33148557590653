import {ClubLabel} from "../types/types";

export function mapClubLabel(str: string): ClubLabel {
  if (str === "club_uk") {
    return "clubUK";
  } else if (str === "club_au") {
    return "clubAU";
  } else if (str === "club_us") {
    return "clubUS";
  } else if (str === "club_nz") {
    return "clubNZ";
  } else if (str === "club_pt") {
    return "clubPT";
  } else if (str === "club_crfu") {
    return "clubCRFU";
  } else if (str === "club_nzpa") {
    return "clubNZPA";
  } else if (str === "club_nzdf") {
    return "clubNZDF";
  }

  throw new Error("Unsupported club" + str);
}

export function isPartnerClub(str: string) {
  return str === "clubNZPA" || str === "clubCRFU" || str === "clubPT" || str === "clubNZDF";
}
