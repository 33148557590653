import {ICheckout, IShippingAddress} from "../types/types";
import {storefrontSdk} from "./sdk";
import config from "./config";

export function isFreeShippingFriday(): boolean {
  if (config.countryCode !== 'NZ') {
    return false;
  }
  const nzTimeString = new Date().toLocaleString("en-US", {timeZone: "Pacific/Auckland"});
  const nzTime = new Date(nzTimeString);

  return nzTime.getDay() === 5;
}

export async function getShippingRatesForCheckout(checkout: ICheckout): Promise<{title: string, price: {amount: string, currencyCode: string}, handle: string}[] | null> {
  const shippingAddress = checkout.shippingAddress ?? checkout.customer?.preferredAddress ?? null;
  if (!shippingAddress) {
    return null;
  }

  const lineItems = checkout.lineItems.map((lineItem) => {
    return {
      variantId: lineItem.variantId,
      quantity: lineItem.quantity,
    }
  });

  if (checkout.bundleItems) {
    for (const bundleItem of checkout.bundleItems) {
      for (const selectedVariant of bundleItem.selectedVariants) {
        lineItems.push({
          variantId: selectedVariant.id,
          quantity: bundleItem.quantity,
        });
      }
    }
  }

  const rates = await getShippingRates(lineItems, shippingAddress);
  if (rates.length > 0) {
    return rates;
  }

  return await getShippingRates(lineItems, {
    country: config.countryCode,
    province: config.defaultShippingProvince,
    city: config.defaultShippingCity,
  });
}

export async function getShippingRates(lineItems: {variantId: string, quantity: number}[], shippingAddress: Partial<IShippingAddress>) {

  if (isFreeShippingFriday()) {
    return [
      {
        title: "Free Shipping Friday",
        price: {
          amount: "0.00",
          currencyCode: "NZD",
        },
        handle: "free-shipping-friday",
      }
    ];
  }

  const storefront = storefrontSdk();
  try {
    const shippingRates = await storefront.getShippingRates({
      input: {
        allowPartialAddresses: true,
        lineItems,
        shippingAddress: {
          firstName: shippingAddress?.firstName,
          lastName: shippingAddress?.lastName,
          address1: shippingAddress?.address1,
          address2: shippingAddress?.address2,
          city: shippingAddress?.city,
          country: shippingAddress?.country,
          province: shippingAddress?.province,
          zip: shippingAddress?.zip,
        }
      }
    });
    if (shippingRates.checkoutCreate?.checkout?.requiresShipping === false) {
      return [{title: 'Digital Delivery', price: {amount: '0.00', currencyCode: config.currencyCode}, handle: 'digital-delivery'}];
    }

    const availableRates = shippingRates.checkoutCreate?.checkout?.availableShippingRates?.shippingRates ?? [];

    return availableRates.map((availableRate) => {
      return {
        title: availableRate.title,
        price: availableRate.price,
        handle: availableRate.handle,
      }
    })
  } catch (error) {
    const checkoutRequiresShipping = error.response.data?.checkoutCreate?.checkout?.requiresShipping ?? null;
    if (checkoutRequiresShipping === false) {
      return [{
        title: 'Digital Delivery',
        price: {amount: '0.00', currencyCode: config.currencyCode},
        handle: 'digital-delivery'
      }];
    }
    return [];
  }

}
