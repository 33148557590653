import React from "react";
import { IGiftItem } from "../types/types";
import { CurrencyCode } from "../types/storefront.generated";

type GiftItemProps = {
  giftItem: IGiftItem;
  currencyCode: CurrencyCode;
};
const GiftItem = ({ giftItem, currencyCode }: GiftItemProps) => (
  <li key={giftItem.id} className="flex items-start space-x-4 py-6">
    <div className={`relative ${giftItem.quantity < 1 ? "opacity-50" : ""}`}>
      <img
        src={giftItem?.imageUrl ?? ""}
        alt={giftItem?.productTitle ?? ""}
        className="w-20 flex-none rounded-md object-cover object-center"
      />
      {giftItem.quantity > 0 && (
        <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-gray-400 rounded-full -top-2 -right-2">
          {giftItem.quantity}
        </div>
      )}
    </div>

    <div className="w-20 flex-auto space-y-1">
      <>
        <h2 className={`${giftItem.quantity < 1 ? "line-through" : ""}`}>
          {giftItem?.productTitle}
        </h2>
        <h3
          className={`font-light text-gray-500 ${giftItem.quantity < 1 ? "line-through" : ""}`}
        >
          {giftItem?.variantTitle}
        </h3>
      </>
    </div>
    <p className="flex-none text-base font-medium">FREE</p>
  </li>
);
export default GiftItem;
