import ReactDOM from "react-dom/client";
import "./index.css";
import React from "react";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import Layout from "./Layout";
import Checkout from "./Checkout";
import Confirm from "./Confirm";
import {CartProvider} from "./CartContext";
import * as Sentry from "@sentry/react";
import config from "./utils/config";
import Complete from "./Complete";
import ErrorFallback from "./components/ErrorFallback";

Sentry.init({
  dsn: config.sentryDsn,
  environment: config.environment,
  tracePropagationTargets: [
    `${config.checkoutUrl}/api`,
  ],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [[400, 599]],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// @ts-ignore Sentry's wrapCreateBrowserRouter has a different signature
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromChildren(
    <Route path="/checkout" element={<Layout />} errorElement={<ErrorFallback />}>
      <Route index element={<div>Nothing to see here</div>} />
      <Route path=":cartId" element={<CartProvider><Checkout /></CartProvider>} />
      <Route
        path="confirm/:cartId"
        element={<CartProvider><Confirm /></CartProvider>}
      />
      <Route
        path="complete/:cartId"
        element={<CartProvider><Complete /></CartProvider>}
      />
    </Route>
  )
);


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
