import React, {useState} from 'react';
import {useCartContext} from "../CartContext";
import config from "../utils/config";
import LoadingSpinner from "./LoadingSpinner";

const GiftCardForm = () => {
  const {cartLoading, addGiftCard, removeGiftCard, checkout} = useCartContext();
  const [giftCard, setGiftCard] = useState('');
  const [giftCardLoading, setGiftCardLoading] = useState(false);
  const [showGiftCardForm, setShowGiftCardForm] = useState(false);
  const [giftCardError, setGiftCardError] = useState<string | null>(null);

  async function handleApplyGiftCard() {
    try {
      const cartId = checkout?.cartId;
      if (!cartId) {
        throw new Error('Error adding gift card. Please try again.');
      }
      setGiftCardLoading(true);
      const response = await fetch(`${config.checkoutUrl}/api/validateGiftCard`, {
        method: "POST",
        body: JSON.stringify({
          code: giftCard,
        })
      });
      if (response.status === 200) {
        await addGiftCard(giftCard);
        setShowGiftCardForm(false);
      } else {
        throw new Error('Invalid gift card');
      }
    } catch (e) {
      setGiftCardError('Invalid gift card');
    } finally {
      setGiftCardLoading(false);
    }
  }

  async function handleRemoveGiftCard() {
    await removeGiftCard();
    setShowGiftCardForm(false);
  }

  return (
    <>
      {!checkout?.giftCard && !showGiftCardForm && (
        <button type="button" className="text-left text-blue-400 underline font-medium"
                onClick={() => setShowGiftCardForm(true)}>Add gift card</button>
      )}
      {!checkout?.giftCard && showGiftCardForm && (
        <div>
          <label htmlFor="discount-code" className="block text-sm font-medium text-gray-700">
            Gift Card
          </label>
          <div className="mt-1 flex space-x-4">
            <input
              type="text"
              onChange={(event) => setGiftCard(event.target.value ?? null)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  return setGiftCard(event.currentTarget?.value ?? null);
                }
              }}
              id="gift-card"
              name="gift-card"
              className="block w-full rounded-full border-gray-300 shadow-sm focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
            />
            {!giftCardLoading && (
              <button
                type="button"
                onClick={handleApplyGiftCard}
                className="rounded-full bg-gray-900 px-4 text-sm font-bold text-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              >
                Apply
              </button>
            )}
            {giftCardLoading && (
              <LoadingSpinner/>
            )}
            <button type="button" onClick={() => setShowGiftCardForm(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                   stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
          {giftCardError && (
            <p className="mt-2 text-sm text-red-600" id="discountCode-error">
              {giftCardError}
            </p>
          )}
        </div>
      )}
      {checkout?.giftCard && (
        <div className="flex items-center justify-flex-end">
          <div
            className="flex items-center shadow-sm justify-center align-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                 stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"/>
            </svg>
            <span className="px-2">{checkout?.giftCard}</span>
            <button type="button" onClick={handleRemoveGiftCard} disabled={cartLoading}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-4 h-4 mr-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default GiftCardForm;
