import React, {useRef, useEffect, useCallback} from "react";
import {useCartContext} from "./CartContext";
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from "./components/LoadingSpinner";
import trackingService from "./analytics/trackingService";
import {getPaymentRequestFromQueryParams} from "./utils/getPaymentRequestFromQueryParams";


const Confirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {checkout, checkoutTotals} = useCartContext();
  const cartId = checkout?.cartId.replace('gid://shopify/Cart/', '');
  const fetched = useRef(false);

  const fetchData = useCallback(async () => {
    fetched.current = true;
    try {
      const queryParams = new URLSearchParams(location.search);
      const paymentRequestData = getPaymentRequestFromQueryParams(queryParams);
      const response = await fetch("/api/confirmPayment", {
        method: "POST",
        body: JSON.stringify({
          ...paymentRequestData,
          cartId: checkout?.cartId
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to confirm payment');
      }
      await trackingService.trackEvent('purchase', {
        checkout: checkout,
        totals: checkoutTotals
      });
      navigate(`/checkout/complete/${cartId}${location.search}`, {
        replace: true,
      });
    } catch (error) {
      console.error('Error: payment not completed', error);
      navigate(`/checkout/${cartId}`, {
        replace: true,
        state: {
          error: 'Unable to complete your payment, please try again'
        }
      });
    }
  }, [cartId, checkout, checkoutTotals, location.search, navigate]);

  useEffect(() => {
    if (!fetched.current && checkout?.cartId && checkoutTotals?.total) {
      fetchData();
    }
  }, [checkout?.cartId, checkoutTotals?.total, fetchData, fetched]);

  return (
    <div className="bg-white">
      <div className="flex flex-col h-screen items-center justify-start text-lg font-bold text-gray-500">
        <img
          src="/bushbuck-logo-square.png"
          alt="Bushbuck"
          className="mt-24 w-36 mb-3 rounded-md p-4 object-cover object-center"
        />
        <LoadingSpinner />
        <p className="mt-4">Loading, please wait...</p>
      </div>
    </div>
  )
    ;
};
export default Confirm;
