import React from "react";
import { IGiftItem } from "../types/types";
import { CurrencyCode } from "../types/storefront.generated";

type GiftItemProps = {
  giftItem: IGiftItem;
  currencyCode: CurrencyCode;
};
const GiftItemMobile = ({ giftItem, currencyCode }: GiftItemProps) => (
  <li className="flex py-6">
    <div className="h-16 w-12 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
      {giftItem.imageUrl && (
        <img
          src={giftItem.imageUrl ?? ""}
          alt={giftItem.variantTitle ?? ""}
          className={`h-full w-full object-cover object-center ${giftItem.quantity < 1 ? "opacity-50" : ""}`}
        />
      )}
    </div>

    <div className="ml-4 flex flex-1 flex-col">
      <div>
        <div className="flex justify-between text-xs font-medium text-gray-900">
          <h3>{giftItem.productTitle}</h3>
          <p className="ml-4">FREE</p>
        </div>
        <p className={`font-light mt-1 text-sm text-gray-500`}>
          {giftItem.variantTitle}
        </p>
      </div>
      {giftItem.quantity > 0 && (
        <div className="flex flex-1 items-end justify-between text-xs">
          <p className="font-light text-gray-500">Qty {giftItem.quantity}</p>
        </div>
      )}
    </div>
  </li>
);
export default GiftItemMobile;
