import React from "react";
import { RadioGroup } from "@headlessui/react";
import classNames from "../utils/classNames";
import {ICustomerAddress} from "../types/types";
import formatAddress from "../utils/formatAddress";

interface IAddressSelectorProps {
  value: ICustomerAddress;
  addresses : ICustomerAddress[];
  disabled: boolean;
  onChange: (address: ICustomerAddress) => Promise<void>;
}

const AddressSelector = ({ value, addresses, disabled, onChange }: IAddressSelectorProps) => {
  return (
    <RadioGroup
      value={value}
      by="id"
      disabled={disabled}
      onChange={(newShippingRate) => onChange(newShippingRate)}
      className={disabled ? 'opacity-50' : ''}
    >
      <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
      <div className="-space-y-px rounded-2xl bg-white">
        {addresses.map((address, addressIdx) => (
          <RadioGroup.Option
            key={address.id}
            value={address}
            className={({ checked }) =>
              classNames(
                addressIdx === 0 ? "rounded-tl-3xl rounded-tr-3xl" : "",
                addressIdx === addresses.length - 1
                  ? "rounded-bl-3xl rounded-br-3xl"
                  : "",
                checked ? "z-10 border-gray-200 bg-gray-50" : "border-gray-200",
                "relative flex cursor-pointer border p-4 focus:outline-none",
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? "bg-white border-gray-600"
                      : "bg-white border-gray-300",
                    "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border border-2 flex items-center justify-center",
                  )}
                  aria-hidden="true"
                >
                  <span className={`rounded-full ${checked ? 'bg-gray-600' : 'bg-white'} w-2 h-2`} />
                </span>
                <span className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? "text-gray-900" : "text-gray-900",
                      "block text-sm font-medium",
                    )}
                  >
                    {formatAddress(address)}
                  </RadioGroup.Label>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
export default AddressSelector;
