import formatPrice from "../utils/formatPrice";
import React from "react";
import {IBundleItem} from "../types/types";
import {CurrencyCode} from "../types/storefront.generated";

type BundleItemProps = {
  bundle: IBundleItem;
  currencyCode: CurrencyCode;
};
const BundleItemMobile = ({ bundle, currencyCode }: BundleItemProps) => (
  <li className="flex py-6">
    <div className="h-16 w-12 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
      {bundle.imageUrl && (
        <img
          src={bundle.imageUrl ?? ''}
          alt={bundle.title ?? ''}
          className="h-full w-full object-cover object-center"
        />
      )}
    </div>

    <div className="ml-4 flex flex-1 flex-col">
      <div>
        <div className="flex justify-between text-xs font-medium text-gray-900">
          <h3>
            {bundle.title}
          </h3>
          {bundle.comparePrice && (
            <div className="flex">
              <p className="ml-4 line-through">{formatPrice(bundle?.comparePrice, currencyCode)}</p>
              <p className="ml-4 text-gray-900">{formatPrice(bundle?.price, currencyCode)}</p>
            </div>
          )}
          {!bundle.comparePrice && (
            <p className="ml-4">{formatPrice(bundle?.price, currencyCode)}</p>
          )}
        </div>
        {bundle.selectedVariants.map((selectedVariant, index) => (
          <p key={`${selectedVariant.id}_${index}`} className="font-light mt-1 text-sm text-gray-500">{selectedVariant?.title}</p>
        ))}
      </div>
      <div className="flex flex-1 items-end justify-between text-xs">
        <p className="font-light text-gray-500">Qty {bundle.quantity}</p>
      </div>
    </div>
  </li>
);
export default BundleItemMobile;
