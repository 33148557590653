import React, { ReactNode, ButtonHTMLAttributes } from "react";

interface LoadingButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean;
  children: ReactNode;
}

const LoadingButton = ({ loading, children, ...rest }: LoadingButtonProps) => {
  return (
    <button
      className="block w-full text-[15px] font-bold tracking-wider py-3 px-[35px] bg-black text-white text-center cursor-pointer h-auto justify-center items-center box-border rounded-full appearance-none hover:scale-105 duration-200 transition-transform mt-5 sm:leading-7 disabled:bg-gray-300"
      disabled={loading}
      {...rest}
    >
      {loading && (
        <div className="flex space-x-2 justify-center items-center py-2">
          <span className="sr-only">Loading...</span>
          <div className="h-3 w-3 bg-white rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div className="h-3 w-3 bg-white rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-3 w-3 bg-white rounded-full animate-bounce"></div>
        </div>
      )}
      {!loading && children}
    </button>
  );
};

export default LoadingButton;
