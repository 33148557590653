import { IAnalyticsProvider } from "./trackingService";
import config from "../utils/config";
import mixpanel from "mixpanel-browser";
import { ICheckout, ICheckoutTotals } from "../types/types";
class MixpanelProvider implements IAnalyticsProvider {
  eventMap: Record<string, (data: any) => Promise<void>> = {
    begin_checkout: this.beginCheckout,
    add_shipping_info: this.addShippingInfo,
    add_payment_info: this.addPaymentInfo,
    purchase: this.purchase
  };
  constructor() {
    mixpanel.init(config.mixpanelToken, {
      track_pageview: "url-with-path-and-query-string"
    });
    if (config.environment === "development") {
      //mixpanel.disable();
    }
    mixpanel.register({
      country: config.countryCode,
      currency: config.currencyCode,
    });
  }

  sentCheckoutStarted: boolean = false;
  sentPurchase: boolean = false;

  async trackEvent(event: string, data: Record<string, any>): Promise<void> {
    if (this.eventMap[event]) {
      await this.eventMap[event](data);
      return;
    }
  }

  async beginCheckout(data: { checkout: ICheckout; totals: ICheckoutTotals }) {
    if (this.sentCheckoutStarted) {
      return Promise.resolve();
    }
    mixpanel.people.set({
      '$firstName': data.checkout.shippingAddress?.firstName,
      '$lastName': data.checkout.shippingAddress?.lastName,
      '$email': data.checkout.email,
    });
    mixpanel.track("Checkout started", {
      cartId: data.checkout.cartId,
      lineItems: data.checkout.lineItems.map((item) => ({
        productId: item.productId,
        productName: item.productTitle,
        variantId: item.variantId,
        variantName: item.variantTitle,
        quantity: item.quantity,
        price: item.price,
        sku: item.sku,
      })),
      ...data.totals
    });
    this.sentCheckoutStarted = true;
    return Promise.resolve();
  }

  private addShippingInfo(data: { checkout: ICheckout, totals: ICheckoutTotals }) {
    mixpanel.people.set({
      '$firstName': data.checkout.shippingAddress?.firstName,
      '$lastName': data.checkout.shippingAddress?.lastName,
      '$email': data.checkout.email,
    });
    mixpanel.track("Shipping address added", {
      cartId: data.checkout.cartId,
      lineItems: data.checkout.lineItems.map((item) => ({
        productId: item.productId,
        productName: item.productTitle,
        variantId: item.variantId,
        variantName: item.variantTitle,
        quantity: item.quantity,
        price: item.price,
        sku: item.sku,
      })),
      ...data.totals
    });
    return Promise.resolve();
  }

  private addPaymentInfo(data: { checkout: ICheckout, totals: ICheckoutTotals }) {
    mixpanel.track("Payment method added", {
      cartId: data.checkout.cartId,
      lineItems: data.checkout.lineItems.map((item) => ({
        productId: item.productId,
        productName: item.productTitle,
        variantId: item.variantId,
        variantName: item.variantTitle,
        quantity: item.quantity,
        price: item.price,
        sku: item.sku,
      })),
      paymentMethod: data.checkout.paymentMethod,
      ...data.totals
    });
    return Promise.resolve();
  }

  private purchase(data: { checkout: ICheckout, totals: ICheckoutTotals }) {
    if (this.sentPurchase) {
      return Promise.resolve();
    }
    mixpanel.track("Payment attempted", {
      cartId: data.checkout.cartId,
      lineItems: data.checkout.lineItems.map((item) => ({
        productId: item.productId,
        productName: item.productTitle,
        variantId: item.variantId,
        variantName: item.variantTitle,
        quantity: item.quantity,
        price: item.price,
        sku: item.sku,
      })),
      paymentMethod: data.checkout.paymentMethod,
      ...data.totals
    });
    this.sentPurchase = true;
    return Promise.resolve();
  }
}
export default MixpanelProvider;
