import React from 'react'
import {Outlet} from "react-router-dom";

const Layout = () => {
    return (<div className="flex flex-col w-full min-h-screen overflow-x-hidden">
        <Outlet />
    </div>)
}


export default Layout;
